import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
export function CallsTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const calls = data.calls;
    const total = data.total;
    const [searchParams, setSearchParams] = useSearchParams();
    const pageSize = parseInt(searchParams.get("per_page") || "20");
    const pageCount = Math.ceil(total / pageSize);
    const accountResearchPageEnabled = useFeatureFlagEnabled("ACCOUNT_RESEARCH_PAGE");
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.playbookItems, accountResearchPageEnabled), [data.playbookItems, accountResearchPageEnabled]);
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = data.playbookItems.map((item) => item.name);
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(FIELDS);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, searchParams);
    const { table } = useDataTable({
        data: calls,
        columns,
        pageCount,
        filterFns: {
            wiserCrmTableFilterFn: (row, column, value) => {
                return true;
            },
        },
        initialState: {
            columnPinning: { right: ["actions"] },
            columnVisibility: Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false, description: false }, Object.fromEntries(playbook_item_names.map((name) => [name, false]))),
        },
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const renderNewToolBar = () => {
        return (_jsx(DataTableToolbarV2, { table: table, columnIdMap: columnIdMap, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, exportComponent: _jsx(CallsTableToolbarActions, { table: table }), onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me calls", sortRuleBuilderPanelTitle: "Sort calls by", initialFilters: initialFilter, initialSortRules: initialSortRules }));
    };
    return (_jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { table: table }) }, { children: renderNewToolBar() })));
}
