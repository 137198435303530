var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSearchParams } from "react-router-dom";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import CrmFieldValue from "./crm_field_value";
import { createCrmContact, createOrUpdateCrmContactData, getCrmAccounts, getCrmContact, getPaginatedCrmData, updateCrmContact, } from "../common/endpoints";
import { useQuery, useQueryClient } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { getCrmAccountName } from "../common/call_account_utils";
import { debounce } from "../common/debounce";
const DEFAULT_CONTACTS_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_CONTACTS_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Contact",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "page=1&per_page=20",
    columns: {
        "row-select": 40,
        name: 150,
        email: 150,
        linkedin_profile_url: 150,
        account: 200,
        last_call_time: 250,
        last_call_link: 150,
        last_call_summary: 400,
    },
};
export const CrmContactsV2 = () => {
    var _a, _b;
    const [searchParams, setSearchParams] = useSearchParams();
    const pageIndex = parseInt(searchParams.get("page") || "1") || 1;
    const pageSize = parseInt(searchParams.get("per_page") || "20") || 20;
    const filter = searchParams.has("filter")
        ? (_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_CONTACTS_FILTER
        : DEFAULT_CONTACTS_FILTER;
    const orderBy = searchParams.has("order_by")
        ? (_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_CONTACTS_ORDER_BY
        : DEFAULT_CONTACTS_ORDER_BY;
    // State to store the CRM contacts keyed by their ID.
    const [crmContactsMap, setCrmContactsMap] = useState(new Map());
    // State to store the CRM accounts keyed by their ID.
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const [searchQuery, setSearchQuery] = useState("");
    // We use debouncedSearchQuery to refetch new select options instead
    // of search query to reduce the number of API calls when user types,
    // and not being reactive on every key-stroke by the user.
    // Why not make the searchQuery state debounced? Because then it makes the user
    // feel that their key-strokes are not being recorded
    const debouncedQuery = debounce(searchQuery, 400);
    const { data: contactsPaginatedData, isFetching: fetchingContacts } = useQuery({
        queryKey: [
            "getPaginatedContacts",
            pageIndex,
            pageSize,
            filter,
            orderBy,
            debouncedQuery,
        ],
        queryFn: () => getPaginatedCrmData("Contact", JSON.stringify({
            table: "Contact",
            structured_filter: {
                where_condition: {
                    operator: WiserBackendQueryOperator.AND,
                    subconditions: [
                        filter,
                        {
                            where_condition: {
                                operator: WiserBackendQueryOperator.OR,
                                subconditions: [
                                    {
                                        where_condition: {
                                            operator: WiserBackendQueryOperator.LIKE,
                                            field: "email",
                                            value: debouncedQuery,
                                        },
                                    },
                                    {
                                        where_condition: {
                                            operator: WiserBackendQueryOperator.LIKE,
                                            field: "name",
                                            value: debouncedQuery,
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
            order_by_v2: orderBy,
        }), pageIndex, pageSize),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: ({ data }) => {
            setCrmContactsMap(new Map(data.map((contact) => [contact.id, contact])));
        },
    });
    const { isLoading: fetchingAccounts } = useQuery({
        queryKey: ["crmAccounts"],
        queryFn: getCrmAccounts,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setCrmAccountsMap(new Map(data.map((account) => [account.id, account])));
        },
    });
    const accountIdToNameMap = useMemo(() => {
        return new Map(Array.from(crmAccountsMap.values()).map((account) => [
            account.id,
            getCrmAccountName(account),
        ]));
    }, [crmAccountsMap]);
    const columnData = useMemo(() => {
        return [
            {
                columnId: "account",
                idToAccountNameMap: new Map(Array.from(crmAccountsMap.values()).map((account) => [
                    account.id,
                    getCrmAccountName(account),
                ])),
            },
        ];
    }, [crmAccountsMap]);
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "flex items-center justify-center pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.contact_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 100 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.contact_name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 100 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.email) !== null && _a !== void 0 ? _a : ""; }, {
                id: "email",
                header: "Email",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 60 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ newValue, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.email = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 60 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.linkedin_profile_url) !== null && _a !== void 0 ? _a : ""; }, {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 500 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ newValue);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.linkedin_profile_url = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 500 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => row.account_id && crmAccountsMap.has(row.account_id)
                ? getCrmAccountName(crmAccountsMap.get(row.account_id))
                : "", {
                id: "account",
                header: "Account",
                cell: (props) => (_jsx(CrmObjectReferenceSwitcher, { idToLabelMap: accountIdToNameMap, selectedId: props.row.original.account_id, onSelectedIdChange: (newAccountId) => __awaiter(void 0, void 0, void 0, function* () {
                        const updatedContact = yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* accountId= */ newAccountId, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedMap = new Map(prevMap);
                            updatedMap.set(updatedContact.id, updatedContact);
                            return updatedMap;
                        });
                    }) })),
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx("span", Object.assign({ className: "line-clamp-4" }, { children: props.getValue() }))),
            }),
        ];
    }, [crmAccountsMap, accountIdToNameMap]);
    const queryClient = useQueryClient();
    const accountsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getPaginatedCrmData("Account", JSON.stringify({
                    table: "Account",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getAccounts", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: getCrmAccountName(account),
                    value: account.id.toString(),
                };
            }),
        };
    });
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([["account_id", accountsFetcher]]);
    }, []);
    const filterFields = useMemo(() => {
        return [
            {
                id: "name",
                name: "Name",
                type: "string",
                meta: {
                    model: "Contact",
                },
            },
            {
                id: "email",
                name: "Email",
                type: "string",
                meta: {
                    model: "Contact",
                },
            },
            {
                id: "account_id",
                name: "Account",
                type: "reference",
                meta: {
                    model: "Contact",
                },
            },
        ];
    }, []);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmContactsMap.values());
    }, [crmContactsMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmContact = yield createCrmContact(newRowData.find((rowData) => rowData.columnId === "contact_name").value, newRowData.find((rowData) => rowData.columnId === "email").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, newRowData.find((rowData) => rowData.columnId === "account").value, crmFieldValues);
        const updatedCrmContact = yield getCrmContact(newCrmContact.id);
        setCrmContactsMap((prevMap) => {
            return new Map([...prevMap, [updatedCrmContact.id, updatedCrmContact]]);
        });
    });
    const onCrmFieldValueChange = (contactId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield createOrUpdateCrmContactData(contactId, crmField.id, newValue);
        setCrmContactsMap((prevMap) => {
            const updatedContact = prevMap.get(contactId);
            if (updatedContact) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedContact.crm_data.some((crmData) => crmData.crm_field.id === crmField.id)) {
                    updatedContact.crm_data = updatedContact.crm_data.map((crmData) => crmData.crm_field.id === crmField.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedContact.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: crmField,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [contactId, updatedContact]]);
            }
            return prevMap;
        });
    });
    if (fetchingContacts || fetchingAccounts || !contactsPaginatedData) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    return (_jsx(CrmTableV2, { crmObject: "Contact", tableRows: tableData, totalRows: contactsPaginatedData.total, pageIndex: pageIndex, pageSize: pageSize, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnData: columnData, defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, searchQueryValue: searchQuery, onSearchQueryChange: setSearchQuery }));
};
