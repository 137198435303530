var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useSearchParams } from "react-router-dom";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import { Button } from "../components/button";
import CrmFieldValue from "./crm_field_value";
import { createCrmAccount, createOrUpdateCrmAccountData, getAllPreCallPrepBlocks, getCrmAccount, getPaginatedCrmData, updateCrmAccount, } from "../common/endpoints";
import { useQuery } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { getFilterFieldType } from "./common/crm_field_utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import ReactMarkdown from "react-markdown";
import { ScrollArea } from "../components/scroll_area";
import { debounce } from "../common/debounce";
const DEFAULT_ACCOUNTS_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_ACCOUNTS_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Account",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "page=1&per_page=20",
    columns: {
        "row-select": 20,
        name: 150,
        website: 150,
        linkedin_profile_url: 150,
        last_modified_time: 250,
        last_call_time: 250,
        last_call_link: 250,
        last_call_summary: 400,
    },
};
export const CrmAccountsV2 = () => {
    var _a, _b;
    const [searchParams, setSearchParams] = useSearchParams();
    const pageIndex = parseInt(searchParams.get("page") || "1") || 1;
    const pageSize = parseInt(searchParams.get("per_page") || "20") || 20;
    const filter = searchParams.has("filter")
        ? (_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_ACCOUNTS_FILTER
        : DEFAULT_ACCOUNTS_FILTER;
    const orderBy = searchParams.has("order_by")
        ? (_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_ACCOUNTS_ORDER_BY
        : DEFAULT_ACCOUNTS_ORDER_BY;
    // State to store the CRM accounts keyed by their ID.
    // These are useful when the user inline edits a column of an account
    const [crmAccountsMap, setCrmAccountsMap] = useState(new Map());
    const [searchQuery, setSearchQuery] = useState("");
    // We use debouncedSearchQuery to refetch new select options instead
    // of search query to reduce the number of API calls when user types,
    // and not being reactive on every key-stroke by the user.
    // Why not make the searchQuery state debounced? Because then it makes the user
    // feel that their key-strokes are not being recorded
    const debouncedQuery = debounce(searchQuery, 400);
    const { data: accountsPaginatedData, isFetching: fetchingAccounts } = useQuery({
        queryKey: [
            "getPaginatedAccounts",
            pageIndex,
            pageSize,
            filter,
            orderBy,
            debouncedQuery,
        ],
        queryFn: () => getPaginatedCrmData("Account", JSON.stringify({
            table: "Account",
            structured_filter: {
                where_condition: {
                    operator: WiserBackendQueryOperator.AND,
                    subconditions: [
                        filter,
                        {
                            where_condition: {
                                operator: WiserBackendQueryOperator.OR,
                                subconditions: [
                                    {
                                        where_condition: {
                                            operator: WiserBackendQueryOperator.LIKE,
                                            field: "name",
                                            value: debouncedQuery,
                                        },
                                    },
                                    {
                                        where_condition: {
                                            operator: WiserBackendQueryOperator.LIKE,
                                            field: "website",
                                            value: debouncedQuery,
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
            order_by_v2: orderBy,
        }), pageIndex, pageSize),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: ({ data }) => {
            setCrmAccountsMap(new Map(data.map((account) => [account.id, account])));
        },
    });
    // Fetch all Account Research Blocks for rendering the research block
    // columns
    const { data: allResearchBlocks } = useQuery({
        queryKey: ["allResearchBlocks"],
        queryFn: getAllPreCallPrepBlocks,
        refetchOnWindowFocus: false,
    });
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsxs("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: [_jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }), _jsx(Link, Object.assign({ to: `/account/${row.original.id}` }, { children: _jsxs(Button, Object.assign({ variant: "ghost", className: "group flex h-8 w-8 hover:bg-wds-gray-2 rounded-full hover:text-wds-blue-3" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/see_details_icon_gray.png", className: "group-hover:hidden max-w-fit w-5 h-5 shrink-0" }), _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/see_details_icon_blue.png", className: "hidden group-hover:block max-w-fit w-5 h-5 shrink-0" })] })) }))] }))),
                size: 10,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.account_name) !== null && _a !== void 0 ? _a : row.name; }, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        setCrmAccountsMap((prevCrmAccountsMap) => {
                            const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                            if (updatedCrmAccount) {
                                updatedCrmAccount.account_name = newValue;
                                return new Map([
                                    ...prevCrmAccountsMap,
                                    [parseInt(props.row.id), updatedCrmAccount],
                                ]);
                            }
                            return prevCrmAccountsMap;
                        });
                        yield updateCrmAccount(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* website= */ undefined, 
                        /* linkedin_profile_url= */ undefined);
                    }) })),
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("website", {
                id: "website",
                header: "Website",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -2, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.website = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ newValue, 
                            /* linkedin_profile_url= */ undefined);
                        }) }));
                },
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("linkedin_profile_url", {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -3, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: (_a = props.getValue()) !== null && _a !== void 0 ? _a : "", onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            setCrmAccountsMap((prevCrmAccountsMap) => {
                                const updatedCrmAccount = prevCrmAccountsMap.get(parseInt(props.row.id));
                                if (updatedCrmAccount) {
                                    updatedCrmAccount.linkedin_profile_url = newValue;
                                    return new Map([
                                        ...prevCrmAccountsMap,
                                        [parseInt(props.row.id), updatedCrmAccount],
                                    ]);
                                }
                                return prevCrmAccountsMap;
                            });
                            yield updateCrmAccount(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* website= */ undefined, 
                            /* linkedin_profile_url= */ newValue);
                        }) }));
                },
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor("last_updated_time", {
                id: "last_modified_time",
                header: "Last modified time",
                cell: (props) => new Date(props.getValue()).toLocaleString(),
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.getValue() })) })) })] }) })),
            }),
            ...(allResearchBlocks !== null && allResearchBlocks !== void 0 ? allResearchBlocks : [])
                .filter((block) => block.research_block_type === "ACCOUNT")
                .map((researchBlock) => columnHelper.accessor((row) => {
                var _a, _b, _c;
                return (_c = (_b = (_a = row.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.id)) === null || _b === void 0 ? void 0 : _b.markdown) !== null && _c !== void 0 ? _c : "";
            }, {
                id: `${researchBlock.id}`,
                header: researchBlock.name,
                cell: (props) => {
                    const content = props.getValue();
                    if (!content || content.length === 0) {
                        return null;
                    }
                    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx(ReactMarkdown, { children: props.getValue() }) })) })] }) }));
                },
                size: 400,
            })),
        ];
    }, [allResearchBlocks]);
    const filterFields = useMemo(() => {
        if (columns.length === 0)
            return [];
        return [
            ...columns
                .filter((column) => { var _a, _b; return !!((_b = (_a = column.meta) === null || _a === void 0 ? void 0 : _a.fieldSchema) === null || _b === void 0 ? void 0 : _b.type); })
                .map((column) => {
                var _a;
                return {
                    id: column.id,
                    name: (_a = column.header) !== null && _a !== void 0 ? _a : column.id,
                    type: getFilterFieldType(column.meta.fieldSchema),
                    meta: {
                        model: "Account",
                    },
                };
            }),
            {
                id: "last_call_time",
                name: "Last call time",
                type: "date",
                meta: {
                    // This is added as an annotation in the Account queryset
                    model: "Account",
                },
            },
            {
                id: "timestamp",
                name: "Last modified time",
                type: "date",
                meta: {
                    model: "Account",
                },
            },
        ];
    }, [columns]);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmAccountsMap.values());
    }, [crmAccountsMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmAccount = yield createCrmAccount(newRowData.find((rowData) => rowData.columnId === "name").value, newRowData.find((rowData) => rowData.columnId === "website").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, crmFieldValues);
        const updatedCrmAccount = yield getCrmAccount(newCrmAccount.id);
        setCrmAccountsMap((prevCrmAccountsMap) => {
            return new Map([
                ...prevCrmAccountsMap,
                [updatedCrmAccount.id, updatedCrmAccount],
            ]);
        });
    });
    const onCrmFieldValueChange = (accountId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield createOrUpdateCrmAccountData(accountId, crmField.id, newValue);
        setCrmAccountsMap((prevMap) => {
            const updatedAccount = prevMap.get(accountId);
            if (updatedAccount) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedAccount.crm_data.some((crmData) => crmData.crm_field.id === crmField.id)) {
                    updatedAccount.crm_data = updatedAccount.crm_data.map((crmData) => crmData.crm_field.id === crmField.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedAccount.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: crmField,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [accountId, updatedAccount]]);
            }
            return prevMap;
        });
    });
    if (fetchingAccounts || !accountsPaginatedData) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    return (_jsx(CrmTableV2, { crmObject: "Account", tableRows: tableData, totalRows: accountsPaginatedData.total, pageIndex: pageIndex, pageSize: pageSize, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: null, columnData: [], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, searchQueryValue: searchQuery, onSearchQueryChange: setSearchQuery }));
};
