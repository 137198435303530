import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "@radix-ui/react-checkbox";
import { Badge } from "./badge";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, } from "./command";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, } from "./dialog";
import { Separator } from "./separator";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger, } from "./drawer_dialog";
const badgeVariants = [
    "default",
    "secondary",
    "destructive",
    "outline",
];
const buttonVariants = [
    "default",
    "destructive",
    "outline",
    "secondary",
    "ghost",
    "link",
];
const ComponentExamples = () => {
    const exampleNameAndContent = [
        {
            name: "Badge",
            content: (_jsx("div", { children: badgeVariants.map((variant) => (_jsx(Badge, Object.assign({ variant: variant, className: "mr-4" }, { children: variant }), variant))) })),
        },
        {
            name: "Button",
            content: (_jsx("div", { children: buttonVariants.map((variant) => (_jsx(Button, Object.assign({ variant: variant, className: "mr-4" }, { children: variant }), variant))) })),
        },
        {
            name: "Calendar",
            content: (_jsx(Calendar, { mode: "single", selected: new Date(), onSelect: (date) => console.log(date) })),
        },
        {
            name: "Checkbox",
            content: (_jsxs("div", { children: [_jsx(Checkbox, { checked: true, onChange: (e) => console.log(e) }), _jsx(Checkbox, { checked: false, onChange: (e) => console.log(e) }), _jsx(Checkbox, { checked: true, disabled: true, onChange: (e) => console.log(e) })] })),
        },
        {
            name: "Command",
            content: (_jsxs(Command, { children: [_jsx(CommandInput, { placeholder: "Type a command or search..." }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsxs(CommandGroup, Object.assign({ heading: "Suggestions" }, { children: [_jsx(CommandItem, { children: "Calendar" }), _jsx(CommandItem, { children: "Search Emoji" }), _jsx(CommandItem, { children: "Calculator" })] })), _jsx(CommandSeparator, {}), _jsxs(CommandGroup, Object.assign({ heading: "Settings" }, { children: [_jsx(CommandItem, { children: "Profile" }), _jsx(CommandItem, { children: "Billing" }), _jsx(CommandItem, { children: "Settings" })] }))] })] })),
        },
        {
            name: "Dialog",
            content: (_jsxs(Dialog, { children: [_jsx(DialogTrigger, { children: "Open" }), _jsx(DialogContent, { children: _jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: "Are you absolutely sure?" }), _jsx(DialogDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })] }) })] })),
        },
        {
            name: "Drawer",
            content: (_jsxs(Drawer, { children: [_jsx(DrawerTrigger, { children: "Open" }), _jsxs(DrawerContent, { children: [_jsxs(DrawerHeader, { children: [_jsx(DrawerTitle, { children: "Are you absolutely sure?" }), _jsx(DrawerDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })] }), _jsxs(DrawerFooter, { children: [_jsx(Button, { children: "Submit" }), _jsx(DrawerClose, { children: _jsx(Button, Object.assign({ variant: "outline" }, { children: "Cancel" })) })] })] })] })),
        },
        {
            name: "Separator",
            content: (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4 items-center" }, { children: [_jsx(Separator, { orientation: "horizontal" }), _jsx(Separator, { orientation: "vertical", className: "h-14" })] }))),
        },
        {
            name: "Tabs",
            content: (_jsxs(Tabs, Object.assign({ defaultValue: "account", className: "w-[400px]", onValueChange: (e) => console.log(e) }, { children: [_jsxs(TabsList, { children: [_jsx(TabsTrigger, Object.assign({ value: "account" }, { children: "Account" })), _jsx(TabsTrigger, Object.assign({ value: "password" }, { children: "Password" }))] }), _jsx(TabsContent, Object.assign({ value: "account" }, { children: "Make changes to your account here." })), _jsx(TabsContent, Object.assign({ value: "password" }, { children: "Change your password here." }))] }))),
        },
    ];
    return (_jsxs("div", Object.assign({ className: "h-screen w-full p-16 sticky bg-white" }, { children: [_jsx("h1", Object.assign({ className: "text-3xl font-bold mb-8" }, { children: "Component Examples" })), _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-8" }, { children: exampleNameAndContent.map((example, index) => (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx("h2", Object.assign({ className: "text-2xl font-bold" }, { children: example.name })), _jsx("div", Object.assign({ className: "p-4 border border-gray-300 rounded-lg" }, { children: example.content }))] }), index))) }))] })));
};
export default ComponentExamples;
