import { useCallback, useMemo } from "react";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
import { accountsFetcher, contactsFetcher, usersFetcher, } from "../calls_table/calls_table_fetchers";
export function useFilterConfig(FIELDS) {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            ["owner_id", usersFetcher],
        ]);
    }, []);
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 3,
            referenceObjectFetcherMap,
        };
    }, [FIELDS]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    return { filterConfig, wiserBackendQueryConverter };
}
export function useInitialFilter(searchParams, wiserBackendQueryConverter) {
    return useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams, wiserBackendQueryConverter]);
}
export function useFilterChange(navigate, searchParams) {
    return useCallback((updatedFilter) => {
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        navigate(`?${updateSearchParams(searchParams, "filter", filterParam)}`);
    }, [navigate, searchParams]);
}
