var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import BaseModal from "../base_modal";
import { useState } from "react";
import { updateCallAndSetParticipants, deleteLiveCall, } from "../common/endpoints";
import WaitingSpinner from "../waiting_spinner";
import ContactView from "../contact_view";
import { confirmModal } from "../common/confirmation_modal";
import { Button } from "../components/button";
import Badge from "../common/badge";
import { CallDetailsChips, CallDetailsChipsOptions, } from "../call_heading/call_details_chips";
import AutojoinDisplay from "./auto_join_display";
const CallHeadingV3 = (props) => {
    const { call } = props;
    const [editingCallOpen, setEditingCallOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const deleteCall = (call) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        yield deleteLiveCall(call.id);
        setIsSaving(false);
        window.location.reload(); // TODO: find a better way to handle deletion
    });
    return (_jsxs("div", Object.assign({ className: "flex w-full px-8 pt-8 border-neutral-200 flex-col justify-start items-start bg-white" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch justify-start items-center inline-flex pr-8" }, { children: [_jsx("div", Object.assign({ className: "grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: [_jsx("div", Object.assign({ className: "text-[24px] font-medium leading-8" }, { children: call.name })), call.is_deleted && (_jsx(Badge, { text: "Deleted meeting", classes: "bg-destructive-light ml-2 border border-destructive-border rounded-full", textClasses: "text-destructive-dark text-xs" })), _jsx(AutojoinDisplay, { call: call })] })) })), _jsx(Link, Object.assign({ to: `/call/${call.id}` }, { children: _jsx(Button, Object.assign({ className: "font-bold mr-2" }, { children: "Join call" })) })), _jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsxs("div", Object.assign({ className: "relative group" }, { children: [_jsx(Button, Object.assign({ variant: "ghost", size: "icon", onClick: () => setEditingCallOpen(true) }, { children: _jsx(PencilIcon, { className: "h-5 w-5" }) })), _jsx("div", Object.assign({ className: "absolute left-0 mt-2 hidden w-max px-2 py-1 bg-black text-white text-sm rounded-md group-hover:block" }, { children: "Edit call" }))] })), _jsxs("div", Object.assign({ className: "relative group" }, { children: [_jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "text-destructive hover:text-destructive-dark hover:bg-destructive-foreground", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            return yield confirmModal(() => __awaiter(void 0, void 0, void 0, function* () { return yield deleteCall(call); }), "Delete", "Are you sure you want to delete this?", "Delete", "Cancel");
                                        }) }, { children: _jsx(TrashIcon, { className: "h-5 w-5" }) })), _jsx("div", Object.assign({ className: "absolute -left-6 mt-2 hidden w-max px-2 py-1 bg-black text-white text-sm rounded-md group-hover:block" }, { children: "Delete call" }))] })), props.onClose && (_jsx("button", Object.assign({ className: "text-wds-gray-4 hover:text-wds-gray-6", onClick: props.onClose }, { children: _jsx(XMarkIcon, { className: "h-5 w-5 m-1" }) })))] }))] })), _jsx(CallDetailsChips, { call: call, updateCall: props.updateSelectedCall, variant: CallDetailsChipsOptions.V3, className: "border-wds-blue-2 bg-wds-blue-1 font-wds-blue-4" }), editingCallOpen && (
            // TODO: this renders at the wrong z-level, and simply moving the z-level doesn't fix it.
            _jsx(BaseModal, { title: "Edit Call", description: `Edit the existing call called ${call.name}`, open: editingCallOpen, setOpen: setEditingCallOpen, reactQuery: true, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    setIsSaving(true);
                    const zoom_meeting_id = fields[2].value && fields[2].value.length !== 0
                        ? Number(fields[2].value)
                        : undefined;
                    const contacts = yield updateCallAndSetParticipants(call.id, fields[0].value, // Name
                    fields[1].value, // Participants
                    fields[4].value, // Time
                    zoom_meeting_id, // Zoom Meeting Id
                    fields[3].value);
                    const updatedCall = Object.assign(Object.assign({}, call), { name: fields[0].value, 
                        // This is a list of contacts
                        participants: contacts.contacts, zoom_meeting_id: fields[2].value, zoom_passcode: fields[3].value, call_time: fields[4].value, supports_meeting_bot: zoom_meeting_id !== undefined });
                    props.updateSelectedCall(updatedCall);
                    setIsSaving(false);
                    setEditingCallOpen(false);
                }), fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: call.name,
                        required: true,
                        placeholder: "Name of this call",
                    },
                    {
                        name: "Participants",
                        type: "text",
                        value: call.participants.map((p) => p.email),
                        placeholder: "Participants",
                    },
                    {
                        name: "Zoom Meeting Id",
                        type: "number",
                        value: call.zoom_meeting_id,
                        placeholder: "Zoom Meeting Id",
                    },
                    {
                        name: "Zoom Passcode",
                        type: "text",
                        value: call.zoom_passcode,
                        placeholder: "Zoom Passcode",
                    },
                    {
                        name: "Time",
                        type: "datetime-local",
                        value: new Date(new Date(call.call_time).getTime() -
                            new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .slice(0, 16),
                        required: true,
                        placeholder: "Start time",
                    },
                ], submitText: isSaving ? _jsx(WaitingSpinner, { text: "Saving..." }) : "Save Changes", disableSubmit: isSaving, children: _jsxs("div", { children: [_jsx("hr", { className: "h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" }), _jsx(ContactView, { contacts: call.participants })] }) }))] })));
};
export default CallHeadingV3;
