var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This file provides an EditGoalModal component. It is used for creating and editing goals.
// It builds on top of the BaseModal to add KeywordTrigger and VerbalTrigger creation,
// association, and deletion.
import { useState } from "react";
import EditTrigger from "./edit_trigger";
import BaseModal from "./base_modal";
import WaitingSpinner from "./waiting_spinner";
import { createPlaybookItemGroup, getPlaybookItemGroupById, updatePlaybookItemGroup, } from "./common/endpoints";
import { useMutation, useQuery, useQueryClient } from "react-query";
export default function EditGoalModal(props) {
    const queryClient = useQueryClient();
    const [isTraining, setIsTraining] = useState(false);
    const { data: playbookItemGroup, isLoading: playbookItemGroupIsLoading, isError: playbookItemGroupIsError, } = useQuery(["playbookItemGroup", props.goalId], () => props.goalId ? getPlaybookItemGroupById(props.goalId) : undefined);
    const submitGoal = (fields) => __awaiter(this, void 0, void 0, function* () {
        return yield createPlaybookItemGroup(fields[0].value, fields[1].value, fields[2].value, fields[4].value, parseInt(fields[5].value, 10) || 0, fields[3].value);
    });
    const updateGoal = (fields) => __awaiter(this, void 0, void 0, function* () {
        if (!props.goalId) {
            return;
        }
        setIsTraining(true);
        const response = yield updatePlaybookItemGroup(props.goalId, fields[0].value, fields[1].value, fields[2].value, fields[4].value, parseInt(fields[5].value, 10) || 0, fields[3].value);
        setIsTraining(false);
        return response;
    });
    const createGoalMutation = useMutation({
        mutationFn: submitGoal,
        onSuccess: (result) => {
            queryClient.setQueryData(["playbookItemGroup", result.id], result);
            props.submitCallback(result.id);
        },
    });
    const updateGoalMutation = useMutation({
        mutationFn: updateGoal,
        onSuccess: (result) => {
            queryClient.setQueryData(["playbookItemGroup", result === null || result === void 0 ? void 0 : result.id], result);
            if (result) {
                props.submitCallback(result.id);
            }
        },
    });
    let submitText = "Create";
    if (props.goalId && props.goalId > 0) {
        submitText = "Save";
    }
    if (isTraining) {
        submitText = _jsx(WaitingSpinner, { text: "Training" });
    }
    if (playbookItemGroupIsLoading) {
        return _jsx(WaitingSpinner, { text: "Loading tracker set..." });
    }
    return (_jsxs(BaseModal, Object.assign({ open: props.open, setOpen: props.setOpen, reactQuery: true, title: props.goalId ? "Edit Tracker" : "Create a Tracker", description: "Trackers are trackable objectives for sales calls. Trackers can be added to your reps' calls through tracker sets.", submitCallback: (fields) => __awaiter(this, void 0, void 0, function* () {
            if (props.goalId) {
                yield updateGoalMutation.mutateAsync(fields);
            }
            else {
                yield createGoalMutation.mutateAsync(fields);
            }
        }), fields: [
            {
                name: "Name",
                value: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.name) || "",
                type: "text",
                required: true,
                placeholder: "Name of this tracker",
            },
            {
                name: "Call stage",
                value: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.call_stage) || "LIVE_CALL",
                type: "radio_selector",
                options: [
                    {
                        label: "Live call",
                        value: "LIVE_CALL",
                    },
                    {
                        label: "Post call only",
                        value: "POST_CALL",
                    },
                    {
                        label: "Follow-up email",
                        value: "FOLLOW_UP_EMAIL",
                    },
                ],
                required: true,
            },
            {
                name: "Team Focus (Emphasize transcript from these speakers)",
                value: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.relevant_speaker) || "CUSTOMER",
                type: "radio_selector",
                options: [
                    {
                        label: "Salesperson",
                        value: "SALESPERSON",
                    },
                    {
                        label: "Customer",
                        value: "CUSTOMER",
                    },
                    {
                        label: "Everyone",
                        value: "ALL",
                    },
                ],
                required: true,
            },
            {
                name: "Use wrapper prompt",
                value: !!(playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.use_wrapper_prompt),
                type: "checkbox",
            },
            {
                name: "Note prompt",
                value: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.note_prompt) || "",
                type: "textarea",
                required: true,
                placeholder: "Note prompt",
            },
            {
                name: "Send reminder X minutes before the end of the call",
                value: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.reminder_minutes) || 0,
                type: "number",
                required: true,
            },
        ], submitText: submitText }, { children: [_jsx(EditTrigger, { intentId: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.intent_id) || -1, triggers: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.verbal_triggers) || [], disabled: !props.goalId, setTriggers: (triggers) => {
                    queryClient.setQueryData(["playbookItemGroup", playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.id], Object.assign(Object.assign({}, playbookItemGroup), { default_item: Object.assign(Object.assign({}, playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item), { verbal_triggers: triggers }) }));
                }, add_trigger_url: "/call_nav/create_verbal_trigger", remove_trigger_url: "/call_nav/delete_verbal_trigger", suggest_url: "/call_nav/suggest_playbook_item_trigger_phrases", title: "Sample questions", info: "Sample questions are examples of questions that would answer this tracker. We train our AI to listen for these types of questions to trigger this tracker.", placeholder: "Add a sample question" }), _jsx(EditTrigger, { disabled: !props.goalId, intentId: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.intent_id) || -1, triggers: (playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item.keyword_triggers) || [], setTriggers: (triggers) => {
                    queryClient.setQueryData(["playbookItemGroup", playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.id], Object.assign(Object.assign({}, playbookItemGroup), { default_item: Object.assign(Object.assign({}, playbookItemGroup === null || playbookItemGroup === void 0 ? void 0 : playbookItemGroup.default_item), { keyword_triggers: triggers }) }));
                }, add_trigger_url: "/call_nav/create_keyword_trigger", remove_trigger_url: "/call_nav/delete_keyword_trigger", title: "Keywords", info: "Keywords are words or phrases that would answer this tracker. These help trigger this tracker if heard in conversation.", placeholder: "Add a keyword" })] })));
}
