var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createColumnHelper } from "@tanstack/react-table";
import { useDataTable } from "../table_hooks/use_data_table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { getCrmFields, getTableLayouts } from "../common/endpoints";
import CrmFieldValue from "./crm_field_value";
import { DataTable } from "../components/data_table";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { getFilterFieldType, getQueryFieldForCrmField, } from "./common/crm_field_utils";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { WiserBackendSortRuleConverter } from "../common/sort_rule_builder/backend_order_by_clause_converter";
import { convertBackendOrderByClausesToSearchParams, convertSearchParamsToBackendOrderBy, } from "../common/sort_rule_builder/search_params_utils";
import { CrmNewRow } from "./crm_new_row";
import { DataTableSkeleton } from "../components/data_table_skeleton";
export const CrmTableV2 = (crmTableProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFieldsQueryKey"],
        queryFn: getCrmFields,
        refetchOnWindowFocus: false,
        // to not fetch everytime this component re-renders
        staleTime: Infinity,
    });
    const { data: tableLayouts, isLoading: tableLayoutsLoading } = useQuery({
        queryKey: ["getTableLayouts", crmTableProps.crmObject],
        queryFn: () => getTableLayouts(crmTableProps.crmObject),
        refetchOnWindowFocus: false,
        // to not fetch everytime this component re-renders
        staleTime: Infinity,
    });
    const columnHelper = createColumnHelper();
    // Memoizing the column defs to avoid re-rendering
    const columnDefs = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError) {
            return [];
        }
        return [
            ...crmTableProps.defaultColumns,
            ...(crmFields !== null && crmFields !== void 0 ? crmFields : [])
                .filter((field) => field.applicable_objects.includes(crmTableProps.crmObject))
                .sort((a, _) => (a.nullable ? 1 : -1))
                .map((field) => columnHelper.accessor((row) => {
                var _a, _b;
                return (_b = (_a = row.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "";
            }, {
                id: `${field.id}`,
                header: () => _jsx("span", Object.assign({ className: "w-max" }, { children: field.name })),
                meta: {
                    fieldId: field.id,
                    fieldSchema: field.content_type_definition,
                    type: field.content_type_definition.type,
                    columnInfo: {
                        columnType: field.column_type,
                        sourcePlaybookItemId: field.source_playbook_item_id,
                    },
                    required: !field.nullable,
                },
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: field.id, crmFieldSchema: field.content_type_definition, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            // TODO: Render a updation spinner in the UI.
                            yield crmTableProps.onCrmFieldValueChange(parseInt(props.row.id), field, newValue);
                        }), crmFieldValueStatus: 
                        // For CRM fields, the status is a no-op since we render the CRM value only.
                        // For enrichment columns, we want to render the computation status as some
                        // background job might still be processing it.
                        field.column_type === "ENRICHMENT"
                            ? (_a = props.row.original.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.status
                            : undefined }));
                },
            })),
        ];
    }, [
        crmTableProps.defaultColumns,
        crmFields,
        crmFieldsLoading,
        crmFieldsError,
    ]);
    const filterFields = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError) {
            return [];
        }
        return [
            ...crmTableProps.defaultFilterFields,
            ...(crmFields !== null && crmFields !== void 0 ? crmFields : [])
                .filter((field) => field.applicable_objects.includes(crmTableProps.crmObject))
                .map((crmField) => {
                return {
                    id: `${crmField.id}`,
                    name: crmField.name,
                    type: getFilterFieldType(crmField.content_type_definition),
                    meta: {
                        model: `Crm${crmTableProps.crmObject}FieldValue`,
                        relatedModelFieldName: `${crmTableProps.crmObject.toLowerCase()}_id`,
                        proxyFieldKey: "crm_field_id",
                        proxyFieldValue: getQueryFieldForCrmField(crmField.content_type_definition),
                    },
                };
            }),
        ];
    }, [
        crmTableProps.defaultFilterFields,
        crmFields,
        crmFieldsLoading,
        crmFieldsError,
    ]);
    const filterConfig = useMemo(() => {
        return {
            fields: filterFields,
            maxSubConditions: 3,
            referenceObjectFetcherMap: crmTableProps.referenceObjectFetcherMap,
        };
    }, [filterFields]);
    const sortRuleConfig = useMemo(() => {
        return {
            // Reference fields are not sortable by default.
            fields: filterFields.filter((field) => field.type !== "reference"),
            maxConditions: 3,
        };
    }, [filterFields]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    const initialFilter = useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams]);
    const wiserBackendSortRuleConverter = useMemo(() => {
        return new WiserBackendSortRuleConverter(sortRuleConfig);
    }, [sortRuleConfig]);
    const initialSortRules = useMemo(() => {
        const beOrderBy = convertSearchParamsToBackendOrderBy(searchParams);
        if (!beOrderBy) {
            return undefined;
        }
        return wiserBackendSortRuleConverter.convertBackendOrderByToSortRules(beOrderBy);
    }, [searchParams]);
    const updateSearchParams = (updatedParams) => {
        const params = new URLSearchParams(searchParams);
        updatedParams.forEach((updatedParam) => {
            if (updatedParam.value) {
                params.set(updatedParam.key, updatedParam.value);
            }
            else {
                params.delete(updatedParam.key);
            }
        });
        return params.toString();
    };
    const onFilterChange = useCallback((updatedFilter) => {
        // Convert the filter to query param format
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        // Only update the 'filter' query param, leaving 'sort' untouched
        navigate(`?${updateSearchParams([
            {
                key: "filter",
                value: filterParam,
            },
            {
                key: "page",
                value: "1",
            },
        ])}`);
    }, []);
    const onSortRulesChange = useCallback((updatedRules) => {
        // Convert the sort rules to query param format
        const sortParam = updatedRules
            ? convertBackendOrderByClausesToSearchParams(updatedRules)
            : null;
        // Only update the 'sort' query param, leaving 'filter' untouched
        navigate(`?${updateSearchParams([
            {
                key: "order_by",
                value: sortParam,
            },
            {
                key: "page",
                value: "1",
            },
        ])}`);
    }, []);
    const { table } = useDataTable({
        data: crmTableProps.tableRows,
        columns: columnDefs,
        pageCount: Math.ceil(crmTableProps.totalRows / crmTableProps.pageSize),
        initialState: {
            pagination: {
                pageIndex: crmTableProps.pageIndex,
                pageSize: crmTableProps.pageSize,
            },
        },
        filterFns: {
            // Not needed since we're doing server side filtering.
            wiserCrmTableFilterFn: (row, column, value) => {
                return false;
            },
        },
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const columnIdMap = useMemo(() => {
        if (!crmFields)
            return {};
        return crmFields.reduce((acc, crmField) => {
            acc[crmField.id] = crmField.name;
            return acc;
        }, {});
    }, [crmFields]);
    const tableFooter = () => {
        if (!table
            .getAllFlatColumns()
            .filter((col) => { var _a; return !!((_a = col.columnDef.meta) === null || _a === void 0 ? void 0 : _a.required); })
            .every((col) => col.getIsVisible())) {
            return null;
        }
        return (_jsx(CrmNewRow, { crmTableInfo: {
                crmObject: crmTableProps.crmObject,
                columns: table.getVisibleFlatColumns(),
                tableState: table.getState(),
                columnData: crmTableProps.columnData,
            }, onNewRowCreate: crmTableProps.onNewRowCreate }));
    };
    if (tableLayoutsLoading) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    return (_jsx("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: _jsx(DataTable, Object.assign({ table: table, footer: tableFooter() }, { children: _jsx(DataTableToolbarV2, { table: table, columnIdMap: columnIdMap, filterConfig: filterConfig, filterPanelTitle: `Show all ${crmTableProps.crmObject}`, onFiltersChange: onFilterChange, initialFilters: initialFilter, sortRuleConfig: sortRuleConfig, sortRuleBuilderPanelTitle: `Sort ${crmTableProps.crmObject} where`, onSortRulesChange: onSortRulesChange, initialSortRules: initialSortRules, layouts: tableLayouts && tableLayouts.length
                    ? tableLayouts
                    : [crmTableProps.defaultTableLayout], defaultTableLayout: crmTableProps.defaultTableLayout, showSearchBar: true, searchQueryValue: crmTableProps.searchQueryValue, onSearchQueryChange: crmTableProps.onSearchQueryChange }) })) })));
};
