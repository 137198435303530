import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import classNames from "../class_names";
import { setAutojoinForCall } from "../common/endpoints";
const AutojoinDisplay = (props) => {
    const [checked, setChecked] = useState(props.call.autojoin && props.call.supports_meeting_bot);
    const tooltipId = "autojoin-" + props.call.id;
    const errorToolTipId = "error-" + props.call.id;
    let cantAutojoinWarning = [];
    if (!props.call.supports_meeting_bot) {
        cantAutojoinWarning.push("This call does not support autojoin.");
    }
    const toolTipText = "".concat(...props.call.errors, ...props.call.warnings, ...cantAutojoinWarning);
    // TODO: Non-zoom calls
    return (_jsxs(_Fragment, { children: [props.call.errors.length == 0 && props.call.supports_meeting_bot && (_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Switch, Object.assign({ checked: checked, onChange: (checked) => {
                            setAutojoinForCall(props.call.id, checked).then(() => {
                                setChecked(checked);
                            });
                        }, disabled: !props.call.supports_meeting_bot || props.disabled, className: classNames(checked ? "bg-wds-blue-3" : "bg-wds-gray-3", "relative inline-flex items-center h-6 rounded-full w-11 transition-colors", props.disabled ? "opacity-70" : ""), "data-tooltip-id": tooltipId, "data-tooltip-content": checked ? "Autojoin enabled" : "Autojoin disabled", "data-tooltip-place": "right", "data-tooltip-offset": 0, "data-tooltip-position-strategy": "fixed" }, { children: _jsx("span", Object.assign({ className: `${checked ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }, { children: _jsx("div", Object.assign({ className: "absolute inset-0 bg-white rounded-full flex items-center justify-center p-0.5" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", alt: "Squiggle", className: "w-full h-full object-contain" }) })) })) })), _jsx(Tooltip, { id: tooltipId, noArrow: true, className: "wiser-frame-tooltip", style: { zIndex: 99 } })] }))), toolTipText.length > 0 && (_jsx("div", Object.assign({ className: "px-2 py-1 justify-start items-center gap-2 inline-flex" }, { children: _jsxs("span", Object.assign({ "data-tooltip-id": errorToolTipId, "data-tooltip-content": toolTipText, "data-tooltip-place": "top", "data-tooltip-offset": 8, "data-tooltip-position-strategy": "fixed" }, { children: [_jsx(InformationCircleIcon, { className: classNames(props.call.errors.length > 0
                                ? "text-red-600"
                                : "text-yellow-600", "w-5 h-5 relative") }), _jsx(Tooltip, { id: errorToolTipId, noArrow: true, className: "wiser-frame-tooltip", style: { zIndex: 99 } })] })) })))] }));
};
export default AutojoinDisplay;
