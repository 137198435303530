var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { DataTableViewOptions } from "./data_table_view_options";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { DataTableLayoutsPopover } from "./data_table_layouts_popover";
import { QueryFilterPopover } from "../common/query_builder/ui/query_filter_popover";
import { SortRuleBuilderPopover } from "../common/sort_rule_builder/ui/sort_rule_builder_popover";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
/**
 * Data table toolbar to render table layout manager, filter, and sort buttons.
 * This is slightly different than the `DataTableNewToolbar` component as this
 * component uses the filter builder, and sort builder components by default thus
 * providing clients the option to handle onFiltersChange, and onSortRulesChange events.
 */
export function DataTableToolbarV2(_a) {
    var { table, columnIdMap, enableFiltering = true, enableSorting = true, enableLayoutManagement = true, filterConfig, sortRuleConfig, layouts, defaultTableLayout, exportComponent, onFiltersChange, onSortRulesChange, filterPanelTitle, sortRuleBuilderPanelTitle, initialFilters, initialSortRules, showSearchBar = false, searchQueryValue, onSearchQueryChange, children, className } = _a, props = __rest(_a, ["table", "columnIdMap", "enableFiltering", "enableSorting", "enableLayoutManagement", "filterConfig", "sortRuleConfig", "layouts", "defaultTableLayout", "exportComponent", "onFiltersChange", "onSortRulesChange", "filterPanelTitle", "sortRuleBuilderPanelTitle", "initialFilters", "initialSortRules", "showSearchBar", "searchQueryValue", "onSearchQueryChange", "children", "className"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const showFilterBuilder = useMemo(() => {
        // The filter builder is only rendered if filterConfig, and onFiltersChange
        // callback is provided to this component.
        return enableFiltering && !!filterConfig && !!onFiltersChange;
    }, [enableFiltering, filterConfig, onFiltersChange]);
    const showSortRuleBuilder = useMemo(() => {
        // The sort rule builder is only rendered if sortRuleConfig, and onSortRulesChange
        // callback is provided to this component.
        return enableSorting && !!sortRuleConfig && !!onSortRulesChange;
    }, [enableSorting, sortRuleConfig, onSortRulesChange]);
    const matchingLayout = useMemo(() => {
        var _a;
        if (!enableLayoutManagement || !layouts) {
            return undefined;
        }
        // This ensures that if you refresh the page you will get the correct layout
        // There is an issue here if you have multiple layouts with the same query string
        // Then it becomes indeterminate. We don't store column visibility in the query string
        // So it's not possible to preserve that state
        const queryString = decodeURIComponent(searchParams.toString()).replace(/%7E/g, "~");
        const matchingLayouts = layouts.filter((layout) => layout.query_string === queryString);
        if (matchingLayouts.length) {
            // If more than 1 layout matches the query string, return the default layout or the first layout
            const defaultLayout = matchingLayouts.find((layout) => layout.is_default);
            return defaultLayout !== null && defaultLayout !== void 0 ? defaultLayout : matchingLayouts[0];
        }
        else {
            // No matching layout return the default one if exists
            return (_a = layouts.find((layout) => layout.is_default)) !== null && _a !== void 0 ? _a : defaultTableLayout;
        }
    }, [searchParams, layouts]);
    const [selectedLayout, setSelectedLayout] = React.useState(matchingLayout);
    React.useEffect(() => {
        // Toggle column visibility based on the views
        if (!selectedLayout) {
            return;
        }
        table.getAllColumns().forEach((column) => {
            if (!column.getIsVisible() &&
                Object.keys(selectedLayout.columns).includes(column.id)) {
                column.toggleVisibility(true);
            }
            else if (column.getIsVisible() &&
                !Object.keys(selectedLayout.columns).includes(column.id)) {
                column.toggleVisibility(false);
            }
        });
        table.setColumnSizing(selectedLayout.columns);
        // Navigate to correct query params based on the selected layout
        const copySearchParams = new URLSearchParams(searchParams.toString());
        let layoutQueryString = selectedLayout.query_string;
        if (copySearchParams.toString() !== selectedLayout.query_string) {
            navigate(`?${layoutQueryString}`);
        }
    }, [
        table.getAllColumns(),
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.columns,
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.query_string,
    ]);
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-between self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [enableLayoutManagement && layouts && (_jsx(DataTableLayoutsPopover, { table: table, layouts: layouts, defaultTableLayout: defaultTableLayout, selectedLayout: selectedLayout, setSelectedLayout: setSelectedLayout })), showFilterBuilder && (_jsx(QueryFilterPopover, { filterConfig: filterConfig, filterPanelTitle: filterPanelTitle !== null && filterPanelTitle !== void 0 ? filterPanelTitle : "Filter rows", onFilterChange: onFiltersChange, initialFilter: initialFilters })), showSortRuleBuilder && (_jsx(SortRuleBuilderPopover, { config: sortRuleConfig, panelTitle: sortRuleBuilderPanelTitle !== null && sortRuleBuilderPanelTitle !== void 0 ? sortRuleBuilderPanelTitle : "Sort row by", onSortRulesChange: onSortRulesChange, initialSortRules: initialSortRules })), _jsx(DataTableViewOptions, { table: table, columnIdMap: columnIdMap })] })), showSearchBar && !!onSearchQueryChange && (_jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500 shrink-0" }), _jsx("input", { type: "text", placeholder: "Search", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: searchQueryValue !== null && searchQueryValue !== void 0 ? searchQueryValue : "", onChange: (e) => {
                            onSearchQueryChange(e.target.value);
                        } })] }))), exportComponent] })));
}
