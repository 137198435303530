var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCrmAccountName } from "../common/call_account_utils";
import { getPaginatedCrmData, getAllUsersInTenant } from "../common/endpoints";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
export const accountsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const accountsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getPaginatedCrmData("Account", JSON.stringify({
                table: "Account",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getAccounts", cursor, userQuery, ids],
    });
    return {
        results: accountsQueryResponse.data.map((account) => {
            return {
                label: getCrmAccountName(account),
                value: account.id.toString(),
            };
        }),
    };
});
export const usersFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const usersQueryResponse = yield queryClient.fetchQuery({
        queryKey: ["getAllUsersInTenant", cursor, userQuery],
        queryFn: () => getAllUsersInTenant(ids, cursor, userQuery),
    });
    return {
        results: usersQueryResponse.items.map((user) => {
            return { label: user.email, value: user.id.toString() };
        }),
        next_cursor: (_a = usersQueryResponse.next_cursor) === null || _a === void 0 ? void 0 : _a.toString(),
    };
});
export const contactsFetcher = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
        };
    }
    const contactsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "email",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getPaginatedCrmData("Contact", JSON.stringify({
                table: "Contact",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getContacts", cursor, userQuery, ids],
    });
    return {
        results: contactsQueryResponse.data.map((contact) => {
            return {
                label: contact.email,
                value: contact.id.toString(),
            };
        }),
    };
});
