var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ShareIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import BaseModal from "./base_modal";
import { createExpiringLinkForCallRecording } from "./common/endpoints";
import { Checkbox } from "./components/checkbox";
import { formatCallTime } from "./strings";
const VideoShareButton = ({ callId, currentVideoTime, }) => {
    const [shareLink, setShareLink] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const handleShare = (type) => __awaiter(void 0, void 0, void 0, function* () {
        if (type === "internal") {
            setShareLink(window.location.href);
            setExpiresAt(null);
        }
        else {
            const resp = yield createExpiringLinkForCallRecording(callId);
            setShareLink(window.location.origin + "/shared/" + resp.link_id);
            setExpiresAt(new Date(resp.expires_at));
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Menu, Object.assign({ as: "div", className: "relative inline-block text-left" }, { children: [_jsx("div", { children: _jsx(Menu.Button, Object.assign({ className: "p-2 rounded-full hover:bg-wds-blue-2 focus:outline-none" }, { children: _jsx(ShareIcon, { className: "w-6 h-6 stroke-wds-gray-5 text-black" }) })) }), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "absolute z-50 left-0 mt-2 w-56 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: _jsxs("div", Object.assign({ className: "py-1" }, { children: [_jsx(Menu.Item, { children: ({ active }) => (_jsx("button", Object.assign({ onClick: () => handleShare("internal"), className: `${active ? "bg-gray-100" : ""} group flex rounded-md items-center w-full px-2 py-2 text-sm` }, { children: "Share Internally" }))) }), _jsx(Menu.Item, { children: ({ active }) => (_jsx("button", Object.assign({ onClick: () => handleShare("external"), className: `${active ? "bg-gray-100" : ""} group flex rounded-md items-center w-full px-2 py-2 text-sm` }, { children: "Share Externally" }))) })] })) })) }))] })), !!shareLink && (_jsx(BaseModal, Object.assign({ title: "Share Link", description: expiresAt
                    ? `This link will expire on ${expiresAt.toLocaleDateString()}.`
                    : "Share the link to the video with others within your organization.", open: !!shareLink, setOpen: (open) => {
                    if (!open) {
                        setShareLink(null);
                        setExpiresAt(null);
                    }
                }, submitText: "Done", submitCallback: () => {
                    setShareLink(null);
                    setExpiresAt(null);
                }, fields: [] }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [currentVideoTime > 1000 && (_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(Checkbox, { defaultChecked: !!new URLSearchParams(new URL(shareLink).search).get("t"), onCheckedChange: (checked) => {
                                        const updatedUrl = new URL(shareLink);
                                        if (checked) {
                                            updatedUrl.searchParams.set("t", Math.round(currentVideoTime / 1000).toString());
                                        }
                                        else {
                                            updatedUrl.searchParams.delete("t");
                                        }
                                        setShareLink(updatedUrl.toString());
                                    } }), "Share video at ", formatCallTime(0, currentVideoTime)] }))), _jsxs("div", Object.assign({ className: "w-full h-12 px-4 py-2 bg-wds-gray-3 rounded-lg justify-between items-center inline-flex hover:bg-wds-blue-2" }, { children: [_jsx("div", { children: shareLink }), _jsx("button", Object.assign({ className: "w-8 h-8 rounded-full justify-center items-center flex hover:bg-wds-blue-2 active:bg-wds-blue-3 transition-colors duration-150", onClick: () => {
                                        navigator.clipboard.writeText(shareLink);
                                    }, "aria-label": "Copy link" }, { children: _jsx(DocumentDuplicateIcon, { className: "w-5 h-5 relative" }) }))] }))] })) })))] }));
};
export default VideoShareButton;
