import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import Badge from "../common/badge";
import { SearchableDropdown } from "../common/searchable_dropdown";
import classNames from "../class_names";
// This component i used to render the editable columns in the table
// that reference other CRM objects. Example: Account in Contacts, etc.
export const CrmObjectReferenceSwitcher = (props) => {
    var _a, _b, _c, _d, _e;
    const [editing, setEditing] = useState(false);
    const newValueDivRef = useRef(null);
    const handleKeyDown = (event) => {
        // Users can come out of the edit mode by pressing Esc.
        if (event.key === "Escape") {
            setEditing(false);
        }
    };
    return (_jsx("div", Object.assign({ className: classNames("p-2 h-full", editing
            ? ""
            : "hover:outline-none hover:cursor-pointer hover:bg-gray-100"), onClick: () => {
            if (editing)
                return;
            setEditing(true);
        } }, { children: editing ? (_jsx("div", Object.assign({ ref: newValueDivRef, onKeyDown: handleKeyDown }, { children: _jsx(SearchableDropdown, { options: Array.from(props.idToLabelMap).map(([id, label]) => ({
                    value: id.toString(),
                    label,
                })), onSelect: (option) => {
                    if (option) {
                        props.onSelectedIdChange(parseInt(option.value));
                        setEditing(false);
                    }
                }, selectedOption: {
                    value: ((_a = props.selectedId) !== null && _a !== void 0 ? _a : 0).toString(),
                    label: (_c = props.idToLabelMap.get((_b = props.selectedId) !== null && _b !== void 0 ? _b : 0)) !== null && _c !== void 0 ? _c : "None",
                }, placeholder: "Select an object" }) }))) : (_jsxs("div", Object.assign({ className: classNames("flex items-start gap-2 break-words whitespace-pre-wrap", props.selectedId ? "justify-between" : "justify-end") }, { children: [props.selectedId && (_jsx(Badge, { text: (_e = props.idToLabelMap.get((_d = props.selectedId) !== null && _d !== void 0 ? _d : 0)) !== null && _e !== void 0 ? _e : "None", classes: "rounded-lg border boreder-gray-300 bg-gray-100 w-fit", textClasses: "text-sm font-medium text-gray-600" })), props.showRequired && _jsx("span", Object.assign({ className: "text-red-700" }, { children: "*" }))] }))) })));
};
