import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "../components/badge";
import { Checkbox } from "../components/checkbox";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import CallTimeDisplay from "../home/call_time_display";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { Button } from "../components/button";
import { Link } from "react-router-dom";
import { getMeetingType, getUserReadableStatus, } from "../video_playback_status_utils";
import ReactMarkdown from "react-markdown";
import { IMAGE_SRCS, MEETING_TEXTS } from "../video_with_transcript_constants";
export function getColumns(playbookItems, accountResearchPageEnabled) {
    // This dedupes the playbook items by name
    const newPlaybookItems = Object.values(playbookItems.reduce((acc, item) => {
        acc[item.name] = item;
        return acc;
    }, {}));
    const playbookTrackerColumns = newPlaybookItems.map((playbookItem) => {
        return {
            accessorKey: playbookItem.name,
            header: playbookItem.name,
            cell: ({ row }) => {
                const playbookItemNotes = JSON.parse(row.getValue("playbook_content") || '{"content": []}');
                const filteredNotes = playbookItemNotes.content.flatMap((notesGroup) => notesGroup.filter((playbookItemNote) => playbookItemNote.name === playbookItem.name));
                const res = filteredNotes.map((note) => note.content).join("");
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: res })) })), _jsx(TooltipContent, { children: _jsx(ReactMarkdown, { children: res }) })] }) }));
            },
        };
    });
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            accessorKey: "name",
            header: "Call Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/post_call/${row.original.id}`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: row.getValue("name") }) })) })) })), _jsx(TooltipContent, { children: row.getValue("name") })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            accessorKey: "call_type",
            header: "Call type",
            cell: ({ row }) => {
                const call_type = ["Internal", "External"].find((call_type) => call_type === row.original.call_type);
                return (_jsx("div", Object.assign({ className: "flex space-x-2" }, { children: call_type && _jsx(Badge, Object.assign({ variant: "outline" }, { children: call_type })) })));
            },
            accessorFn: (row) => row.call_type,
        },
        {
            accessorKey: "wiser_joined",
            header: "Wiser joined",
            cell: ({ row }) => {
                if (!row.original.wiser_joined)
                    return null;
                return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [_jsx(CheckCircleIcon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "capitalize" }, { children: "Recorded" }))] })));
            },
            filterFn: (row, id, value) => {
                return Array.isArray(value) && value.includes(row.getValue(id));
            },
            accessorFn: (row) => row.wiser_joined,
        },
        {
            accessorKey: "call_state",
            header: "Call state",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })] }) })),
            accessorFn: (row) => row.call_state,
        },
        {
            accessorKey: "call_time",
            header: "Call time (local)",
            cell: ({ row, cell }) => (_jsx("div", Object.assign({ className: "w-[150px]" }, { children: _jsx(CallTimeDisplay, { call: row.original, showDate: true, showCalendarIcon: false }) }))),
            accessorFn: (row) => row.call_time,
        },
        {
            accessorKey: "playbook_content",
            header: "Playbook content",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View tracker notes" }) })) }))),
            enableHiding: false,
        },
        {
            accessorKey: "playbook_items",
            header: "Tracker notes",
            // This cell is rendered as a collapsed JSON. Once design mocks are there rework on this.
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Button, Object.assign({ variant: "link", size: "sm" }, { children: "View tracker notes" })) })) })), _jsx(TooltipContent, { children: _jsx("ul", { children: cell.getValue().map((playbookItemName) => (_jsx("li", { children: playbookItemName }, playbookItemName))) }) })] }) })),
            accessorFn: (row) => row.playbook_items,
        },
        {
            accessorKey: "description",
            header: "Description",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })] }) })),
            accessorFn: (row) => row.description,
        },
        {
            accessorKey: "call_source",
            header: "Call source",
            cell: ({ row }) => (_jsxs("div", Object.assign({ className: "flex justify-start items-center w-full text-wds-gray-6 text-sm" }, { children: [_jsx("img", { src: IMAGE_SRCS.get(getMeetingType(row.original)), className: "h-4 w-4 mx-2" }), MEETING_TEXTS.get(getMeetingType(row.original))] }))),
        },
        {
            accessorKey: "participants",
            header: "Participants",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.participants
                                    .filter((participant) => participant.email !== row.original.host_email)
                                    .map((participant) => (_jsx("div", { children: participant.email }, participant.email))) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: row.original.participants
                                    .filter((participant) => participant.email !== row.original.host_email)
                                    .map((participant) => (_jsx("div", { children: participant.email }, participant.email))) })) })] }) })),
            accessorFn: (row) => row.description,
        },
        {
            accessorKey: "call_duration_minutes",
            header: "Call duration minutes",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            accessorKey: "post_call_all_slack_items_sent",
            header: "All slack items sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            accessorKey: "post_call_any_slack_item_sent",
            header: "Any slack item sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            accessorKey: "post_call_crm_updates_mapped",
            header: "CRM updates mapped",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            accessorKey: "post_call_crm_updates_pushed",
            header: "CRM updates processed",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            accessorKey: "meeting_host",
            header: "Meeting host",
            cell: ({ row }) => row.original.host_email,
        },
        {
            accessorKey: "account_name",
            header: "Account",
            cell: ({ cell, row }) => accountResearchPageEnabled ? (_jsx(Button, Object.assign({ variant: "link", size: "sm" }, { children: _jsx(Link, Object.assign({ to: `/account/${row.original.account_id}`, target: "_blank" }, { children: cell.getValue() })) }))) : (cell.getValue()),
            accessorFn: (row) => row.account_name,
        },
        {
            accessorKey: "opportunity_name",
            header: "Opportunity",
            cell: ({ cell }) => cell.getValue(),
            accessorFn: (row) => row.opportunity_name,
        },
        {
            accessorKey: "transcripts",
            header: "Transcripts",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#transcript`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View transcripts" }) })) }))),
            accessorFn: (row) => row.transcripts,
        },
        ...playbookTrackerColumns,
    ];
}
export function getColumnIdColumnNameMap() {
    // Returns list of column id and column name
    // Only for those that have "React.Node" type header
    return {
        select: "Select",
        name: "Call Name",
        call_type: "Call type",
        call_time: "Call time",
        wiser_joined: "Wiser joined",
        playbook_items: "Tracker notes",
        account_name: "Account",
        opportunity_name: "Opportunity",
    };
}
