import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Searchable dropdown component which lets the user find items
// in the dropdown list by typing in the search bar.
// Internally it uses the cmdk Command component along with Radix Popover.
import { Command, CommandGroup, CommandItem, CommandList, } from "../components/command";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import classNames from "../class_names";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
export const SearchableDropdown = (props) => {
    var _a, _b;
    const [searchQuery, setSearchQuery] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const selectItems = useMemo(() => {
        return props.options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery]);
    useEffect(() => {
        var _a, _b;
        setSearchQuery((_b = (_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : "");
    }, [props.selectedOption]);
    return (_jsxs(Popover, Object.assign({ open: isPopoverOpen, onOpenChange: setIsPopoverOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: classNames("flex px-3 w-full h-8 items-center gap-2 hover:cursor-pointer", "border border-wds-gray-3 rounded-lg justify-between self-strech bg-white", (_a = props.buttonClasses) !== null && _a !== void 0 ? _a : "") }, { children: [_jsx("input", { className: "p-1 w-full text-sm font-medium border-none bg-transparent focus:ring-0 focus:outline-none placeholder:text-wds-gray-4 rounded", placeholder: (_b = props.placeholder) !== null && _b !== void 0 ? _b : "", onChange: (e) => {
                                setSearchQuery(e.target.value);
                            }, value: searchQuery, onBeforeInput: () => setIsPopoverOpen(true), autoFocus: true }), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0 text-wds-gray-6" })] })) })), _jsx(PopoverContent, Object.assign({ align: "start", side: "bottom", className: "w-full bg-white shadow-md rounded-md p-0 max-h-60 overflow-y-auto" }, { children: _jsx(Command, { children: _jsx(CommandList, { children: !selectItems || selectItems.length === 0 ? (_jsx("div", Object.assign({ className: "p-2 text-gray-700" }, { children: "Nothing found." }))) : (_jsx(CommandGroup, { children: selectItems.map((option) => {
                                var _a;
                                const isSelected = ((_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.value) === option.value;
                                return (_jsxs(CommandItem, Object.assign({ className: "flex items-center justify-between gap-2 px-4 py-2 cursor-pointer", onSelect: () => {
                                        props.onSelect(option);
                                        setIsPopoverOpen(false);
                                    } }, { children: [_jsx("span", { children: option.label }), isSelected && (_jsx(CheckIcon, { className: "w-4 h-4 text-blue-600 shrink-0" }))] }), option.value));
                            }) })) }) }) }))] })));
};
