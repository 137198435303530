import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { CallsTable } from "./calls_table";
import { convertNewSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getCallsForTable, getPlaybookItems, getTableLayouts, } from "../common/endpoints";
import { useEffect } from "react";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
export default function IndexPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const filterQuery = convertNewSearchParamsIntoFilterQuery(searchParams, "LiveCall");
    const page = parseInt(searchParams.get("page") || "1") || 1;
    const perPage = parseInt(searchParams.get("per_page") || "20") || 20;
    const { data: layouts, isLoading: layoutsLoading, isError: layoutsError, } = useQuery(["getTableLayouts", "calls"], () => getTableLayouts("calls"), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { data, isLoading, isError } = useQuery(["callsTable", filterQuery, page, perPage], () => getCallsForTable(filterQuery, page, perPage), {
        refetchOnWindowFocus: false,
        keepPreviousData: true, // Does not re-render the page untill data is loaded
    });
    const { data: playbookItems } = useQuery("playbookItems", getPlaybookItems);
    const calls = (data === null || data === void 0 ? void 0 : data.data) || [];
    const total = (data === null || data === void 0 ? void 0 : data.total) || 0;
    const wiser_joined_count = (data === null || data === void 0 ? void 0 : data.wiser_joined_count) || 0;
    useEffect(() => {
        if (!layoutsLoading && !layoutsError && !layouts) {
            queryClient.setQueryData(["getTableLayouts", "calls"], [DEFAULT_TABLE_LAYOUT]);
        }
    }, [layoutsLoading, layoutsError, layouts]);
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl p-2" }, { children: "Calls" })), !data || isLoading || !playbookItems || layoutsLoading || !layouts ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, playbookItems: playbookItems, layouts: layouts }))] })));
}
