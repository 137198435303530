export function updateSearchParams(searchParams, key, value) {
    const params = new URLSearchParams(searchParams);
    if (value) {
        params.set(key, value);
    }
    else {
        params.delete(key);
    }
    return params.toString();
}
