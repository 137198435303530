var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as endpoints from "../common/endpoints";
import CallHeading from "./call_heading";
import FeedAssist from "./feed_assist";
import ManualNotes from "./manual_notes";
import { useStreamSocket, } from "../common/stream_socket";
import useChatMessages from "../live_cards_chat_bot/use_chat_messages";
import Transcript from "./transcript";
import { markLatestActivityTime } from "../activity_utils";
import { Mosaic, getAndAssertNodeAtPathExists, updateTree, } from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import "./index.css";
import useInterval from "../use_interval";
import { getOrCreateUserProfile, updateUserProfile } from "../common/endpoints";
import { LiveCallMainDashboard } from "./live_call_main_dashboard";
import { getDefaultMosaicStateForVersion, getMosaicStateForUser, } from "./mosaic_state";
import LiveCardsChatBot from "../live_cards_chat_bot";
import WaitingSpinner from "../waiting_spinner";
import { CHAT_SOURCES, KNOWLEDGE_BASE_CHAT_SOURCE, } from "../live_cards_chat_bot/chat_source_menu";
import { useLlmOutputFeedback } from "../llm_output_feedback/use_llm_output_feedback";
import { useQuery, useQueryClient } from "react-query";
// Min width of the tracker container in mosaic where the
// tracker panel looks fine in open state.
// If the tracker container goes below this width, the tracker panel
// is collapsed by default.
const MIN_TRACKER_WIDTH_PIXELS = 600;
const LiveCall = () => {
    const { callIdParam } = useParams();
    const callId = Number(callIdParam);
    const [call, setCall] = useState(null);
    // Maps intent to note section.
    const [notesMap, setNotesMap] = useState(new Map());
    const [transcript, setTranscript] = useState([]);
    const [startTimeMsec, setStartTimeMsec] = useState(0);
    const [cards, setCards] = useState([]);
    const [zoomBotStatus, setZoomBotStatus] = useState("");
    const [speakerToRoleSwitchEntryMap, setSpeakerToRoleSwitchEntryMap] = useState(new Map());
    const [manualNoteEnabled, setManualNoteEnabled] = useState(false);
    const [manualNote, setManualNote] = useState("");
    const [talkTimeMetrics, setTalkTimeMetrics] = useState(null);
    const [speechRateMetrics, setSpeechRateMetrics] = useState(null);
    const [speechRateMetricsUpdated, setSpeechRateMetricsUpdated] = useState(true);
    const [userProfile, setUserProfile] = useState();
    const [liveCallSummaries, setLiveCallSummaries] = useState([]);
    const [tenant, setTenant] = useState();
    const { chatMessages, addChatMessage, handlePlaybookRecommendations, handleChatMessage, handleEchoChat, loadChatHistory, handleChatProgress, } = useChatMessages();
    // Maps intent to note section.
    const notesRef = useRef(notesMap);
    const cardsRef = useRef(cards);
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    const roleSwitcherRef = useRef(null);
    const [feedCollapsed, setFeedCollapsed] = useState(false);
    const [trackerItemsPanelCollapsed, setTrackerItemsPanelCollapsed] = useState(false);
    const [mosaicState, setMosaicState] = useState(null);
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const [transcriptScrollToBottom, setTranscriptScrollToBottom] = useState(false);
    // Maps playbook id to the last time that playbook id was relevant.
    const relevanceActivatedTimeMsecs = useRef(new Map());
    // Maps playbook id to the last time Wiser recommended questions for that playbook.
    const lastRecommendationTimeMsecs = useRef(new Map());
    // Lets other components know that Mosaic tiles are being dragged
    const [isMosaicScrolling, setIsMosaicScrolling] = useState(false);
    const minChatWidthPixels = 492; // Min width where it all looks good.
    const mosaicStateLiveVersion = 3;
    // For jwt token refresh
    markLatestActivityTime();
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId });
    useEffect(() => {
        getOrCreateUserProfile().then((profile) => setUserProfile(profile));
        let intervalId = setInterval(expireRelevance, 1000);
        return () => {
            streamSocket.close();
            clearInterval(intervalId);
        };
    }, []);
    useQuery({
        queryKey: ["tenant"],
        queryFn: endpoints.getActiveTenant,
        onSuccess: setTenant,
    });
    useEffect(() => {
        notesRef.current = notesMap;
    }, [notesMap]);
    useEffect(() => {
        cardsRef.current = cards;
    }, [cards]);
    useEffect(() => {
        streamSocket.addListener("display_transcript", handleTranscriptResponse);
        streamSocket.addListener("display_notes", handleNotesResponse);
        streamSocket.addListener("content_cards", handleCardResponse);
        streamSocket.addListener("chat_progress", handleChatProgress);
        streamSocket.addListener("zoom_bot_status", (message) => {
            if (!message.zoom_bot_status)
                return;
            setZoomBotStatus(message.zoom_bot_status);
        });
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.addListener("merged_reminders", handleReminder);
        streamSocket.addListener("talk_time_metrics", handleTalkTimeMetrics);
        streamSocket.addListener("speech_rate_metrics", handleSpeechRateMetrics);
        streamSocket.addListener("playbook_recommendations", (message) => {
            setFeedCollapsed(false);
            const playbook_item_id = handlePlaybookRecommendations(message);
            if (playbook_item_id) {
                lastRecommendationTimeMsecs.current.set(playbook_item_id, Date.now());
            }
        });
        // Ensures the notes get saved.
        streamSocket.addListener("notes_saved", (message) => { });
        streamSocket.addListener("manual_note", handleManualNote);
        // TODO: this is not currently displayed anywhere.
        // streamSocket.addListener("llm_relevance", handlRelevanceResponse);
        streamSocket.addListener("echo_back_chat", (message) => handleEchoChat(message, clientId));
        streamSocket.addListener("live_call_realtime_summary", handleRealtimeLiveCallSummary);
        // Don't prune the post-call stages.
        streamSocket.addListener("post_call_created_from_notes", () => { });
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
        streamSocket.setActiveCall(callId);
        endpoints
            .getChatHistory(callId)
            .then((chatHistory) => loadChatHistory(chatHistory, clientId));
        endpoints.getLiveCallSummaries(callId).then(setLiveCallSummaries);
        endpoints.getCallTranscript(callId).then((transcriptResponse) => {
            setTranscript(transcriptResponse.transcript);
            if (transcriptResponse.transcript.length > 0) {
                setStartTimeMsec(transcriptResponse.transcript[0].startTime);
            }
        });
    }, [callId]);
    const setCallAndNotes = ({ call, notes }) => {
        setCall(call);
        const notesMap = new Map(notes.map((note) => [note.intent, note]));
        setNotesMap(notesMap);
    };
    const queryClient = useQueryClient();
    useQuery({
        queryKey: ["getLiveCall", callId],
        queryFn: () => endpoints.getLiveCall(callId),
        onSuccess: setCallAndNotes,
    });
    const updateCall = (callToBeUpdated) => {
        queryClient.invalidateQueries({
            queryKey: ["getLiveCall", callToBeUpdated.id],
        });
    };
    const updateSpeechRateMetrics = (intervalMsecs) => {
        if (speechRateMetricsUpdated) {
            setSpeechRateMetricsUpdated(false);
        }
        else {
            setSpeechRateMetrics((currentMetrics) => {
                if (!currentMetrics)
                    return currentMetrics;
                return Object.assign(Object.assign({}, currentMetrics), { salespersonSpeechDurationMsec: currentMetrics.salespersonSpeechDurationMsec + intervalMsecs, time_since_start: currentMetrics.time_since_start + intervalMsecs });
            });
        }
    };
    const chatShouldSnapClosed = (currentNode) => {
        const pathToChatSplit = [];
        const playbookFeedContainer = getAndAssertNodeAtPathExists(currentNode, pathToChatSplit);
        const playbookPercent = playbookFeedContainer.splitPercentage;
        if (!playbookPercent)
            return false;
        return (((100 - playbookPercent) / 100.0) * window.innerWidth < minChatWidthPixels);
    };
    const shouldCollapseTrackerItemsPanel = (currentNode) => {
        const pathToChatSplit = [];
        const dashboardContainer = getAndAssertNodeAtPathExists(currentNode, pathToChatSplit);
        const trackerPercent = dashboardContainer.splitPercentage;
        if (!trackerPercent)
            return false;
        return ((trackerPercent / 100.0) * window.innerWidth < MIN_TRACKER_WIDTH_PIXELS);
    };
    useEffect(() => {
        if (userProfile) {
            const userMosaicState = getMosaicStateForUser(mosaicStateLiveVersion, userProfile);
            setMosaicState(userMosaicState);
            if (!feedCollapsed && chatShouldSnapClosed(userMosaicState)) {
                setFeedCollapsed(true);
            }
            if (!trackerItemsPanelCollapsed &&
                shouldCollapseTrackerItemsPanel(userMosaicState)) {
                setTrackerItemsPanelCollapsed(true);
            }
            return;
        }
        setMosaicState(getDefaultMosaicStateForVersion(mosaicStateLiveVersion));
    }, [userProfile, mosaicStateLiveVersion]);
    const snapMosaic = (currentNode) => {
        // Snaps the chat feed closed if the user drags it to a certain width
        // or clicks the collapse button.
        if (!feedCollapsed && !chatShouldSnapClosed(currentNode))
            return currentNode;
        const path = [];
        const openPercentage = Math.min(70, Math.round(100 * (1 - minChatWidthPixels / window.innerWidth)));
        const closedPercentage = Math.round(100 * (1 - 48 / window.innerWidth));
        const updatedTree = updateTree(currentNode, [
            {
                path,
                spec: {
                    splitPercentage: {
                        $set: feedCollapsed ? closedPercentage : openPercentage,
                    },
                },
            },
        ]);
        return updatedTree;
    };
    useEffect(() => {
        if (!mosaicState)
            return;
        const updatedTree = snapMosaic(mosaicState);
        setMosaicState(updatedTree);
    }, [feedCollapsed, mosaicState]);
    useInterval(() => {
        // We smooth speech rates every 5 seconds
        updateSpeechRateMetrics(5000);
    }, 5000);
    const handleTranscriptResponse = (response) => {
        if (!response.display_transcript)
            return;
        let preserveBefore = response.display_transcript.transcript[0].audio_chunks_start_msec[0];
        let newTranscripts = response.display_transcript.transcript.map((flattenedTranscript) => {
            var _a;
            let transcriptEntry = {
                id: flattenedTranscript.client_id.toString(),
                speaker: flattenedTranscript.speaker,
                text: flattenedTranscript.final_text,
                startTime: flattenedTranscript.audio_chunks_start_msec[0],
                endTime: flattenedTranscript.transcribe_end_msec,
                role: (_a = flattenedTranscript.role) !== null && _a !== void 0 ? _a : "Customer",
                audioSource: flattenedTranscript.audio_source,
                contactId: flattenedTranscript.contact_id,
            };
            return transcriptEntry;
        });
        setTranscript((prevTranscript) => {
            let oldTranscripts = prevTranscript.filter((entry) => {
                return entry.startTime < preserveBefore;
            });
            return [...oldTranscripts, ...newTranscripts];
        });
        setStartTimeMsec((prevStartTime) => {
            if (response.display_transcript &&
                (response.display_transcript.start_time_msec < prevStartTime ||
                    prevStartTime === 0)) {
                return response.display_transcript.start_time_msec;
            }
            return prevStartTime;
        });
    };
    const handleNotesResponse = (response) => {
        if (!response.display_notes)
            return;
        updateNotesMap(response.display_notes);
    };
    const updateNotesMap = (notes) => {
        setNotesMap((prevNotesMap) => {
            const updatedNotesMap = new Map(prevNotesMap);
            notes.forEach((note) => {
                updatedNotesMap.set(note.intent, note);
            });
            return updatedNotesMap;
        });
    };
    // Relevance tends to flicker on and off, but on the backend, once
    // we get a relevance signal, it does trigger potential note-taking.
    //
    // So, to represent that in the UI, we'll show the listening indicator
    // for three seconds after it shows as relevant. Note that we will also
    // turn off the indicator if a complete note on that topic comes back.
    const handlRelevanceResponse = (response) => {
        if (!response.llm_relevance)
            return;
        setNotesMap((prevNotesMap) => {
            const updatedNotesMap = new Map(prevNotesMap);
            updatedNotesMap.forEach((note) => {
                response.llm_relevance.relevant_ids.forEach((playbookItemId) => {
                    if (note.playbook_item_id === playbookItemId) {
                        note.listening = true;
                        relevanceActivatedTimeMsecs.current.set(playbookItemId, Date.now());
                    }
                });
            });
            return updatedNotesMap;
        });
    };
    const expireRelevance = () => {
        const now = Date.now();
        let keysToDelete = [];
        relevanceActivatedTimeMsecs.current.forEach((time, playbookItemId) => {
            if (now - time > 3000) {
                setNotesMap((prevNotesMap) => {
                    const updatedNotesMap = new Map(prevNotesMap);
                    updatedNotesMap.forEach((note) => {
                        if (note.playbook_item_id === playbookItemId) {
                            note.listening = false;
                        }
                    });
                    return updatedNotesMap;
                });
                keysToDelete.push(playbookItemId);
            }
        });
        keysToDelete.forEach((key) => {
            relevanceActivatedTimeMsecs.current.delete(key);
        });
    };
    const handleManualNote = (response) => {
        if (!response.manual_note)
            return;
        // If this is just the echo-back from the this client, ignore it.
        // We will only set manual notes coming from multiplayer editors or
        // on resuming a call.
        if (response.manual_note.client_id === clientId)
            return;
        setManualNote(response.manual_note.text);
        if (!manualNoteEnabled) {
            setManualNoteEnabled(true);
        }
    };
    const handleCardResponse = (response) => {
        if (!response.content_cards)
            return;
        if (response.content_cards.length) {
            const updatedCards = [...cardsRef.current];
            // step 1, add any newly received currentBestCards to cards list
            for (const newCard of response.content_cards) {
                const cardExists = updatedCards.some((existingCard) => existingCard.intent === newCard.intent && !existingCard.removed);
                if (!cardExists) {
                    updatedCards.push(newCard);
                    // Record the card render count event in a non-blocking manner.
                    endpoints.recordCardEvent(newCard, callId, "SHOWN");
                }
            }
            // step 2, limit the visible cards length to 3, if there are excess, mark them for removal
            const visibleCards = updatedCards.filter((card) => !card.removed && !card.removing);
            const excessCardCount = visibleCards.length - 3;
            if (excessCardCount > 0) {
                for (const cardToMark of visibleCards.slice(0, excessCardCount)) {
                    const cardIndex = updatedCards.indexOf(cardToMark);
                    updatedCards[cardIndex].removing = true;
                    setTimeout(() => {
                        // We are passing a function to setCards instead of a value because we want to ensure that we are working with the most current state.
                        // In React, state updates may be asynchronous, and so directly reading from the state may lead to outdated values.
                        // By passing a function, we ensure that we are working with the state at the time this update is applied.
                        setCards((prevCards) => {
                            const updatedState = [...prevCards];
                            const indexInState = updatedState.indexOf(cardToMark);
                            if (indexInState !== -1) {
                                updatedState[indexInState].removed = true;
                                updatedState[indexInState].removing = false;
                            }
                            // Note, we cannot just remove it from the list yet, as card.removed
                            // signals to the underlying carousel that it should be removed from
                            // the UI first.
                            return updatedState;
                        });
                    }, 3 * 1000); // Clear cards quickly if others are in line.
                }
            }
            setCards(updatedCards);
        }
    };
    const handleReminder = (response) => {
        if (!response.merged_reminders)
            return;
        const chatMessage = response.merged_reminders.stage_content;
        addChatMessage({
            sender: "Bot",
            messageType: "Card",
            card: chatMessage,
        });
    };
    const handleTalkTimeMetrics = (message) => {
        if (!message.talk_time_metrics)
            return;
        setTalkTimeMetrics({
            totalTalkTime: message.talk_time_metrics.total_talk_time,
            salespersonTalkTime: message.talk_time_metrics.salesperson_talk_time,
        });
    };
    const handleSpeechRateMetrics = (message) => {
        if (!message.speech_rate_metrics)
            return;
        setSpeechRateMetricsUpdated(true);
        setSpeechRateMetrics({
            salespersonWordCount: message.speech_rate_metrics.salesperson_word_count,
            salespersonSpeechDurationMsec: message.speech_rate_metrics.salesperson_speech_duration_msec,
            number_of_transcriptions: message.speech_rate_metrics.number_of_transcriptions,
            time_since_start: message.speech_rate_metrics.time_since_start,
        });
    };
    const handleRealtimeLiveCallSummary = (message) => {
        if (!message.live_call_realtime_summary ||
            !message.live_call_realtime_summary.summary_html) {
            return;
        }
        const latestSummary = message.live_call_realtime_summary;
        setLiveCallSummaries((prev) => [
            latestSummary,
            ...prev.filter((summary) => summary.id !== latestSummary.id),
        ]);
    };
    const getSpeakerKey = (speaker, audioSource) => {
        if (!audioSource) {
            return speaker;
        }
        if (audioSource === speaker) {
            return speaker;
        }
        return `${speaker}(${audioSource})`;
    };
    const speakerToRolesMap = useMemo(() => {
        const speakerToRoleSwitchEntry = new Map();
        transcript.forEach((transcriptEntry) => {
            var _a;
            speakerToRoleSwitchEntry.set(getSpeakerKey(transcriptEntry.speaker, transcriptEntry.audioSource), {
                speaker: transcriptEntry.speaker,
                role: (_a = transcriptEntry.role) !== null && _a !== void 0 ? _a : "Unidentified",
                audioSource: transcriptEntry.audioSource,
            });
        });
        Array.from(speakerToRoleSwitchEntryMap.entries()).forEach(([speakerKey, roleSwitchEntry], i) => {
            speakerToRoleSwitchEntry.set(speakerKey, roleSwitchEntry);
        });
        return speakerToRoleSwitchEntry;
    }, [transcript, speakerToRoleSwitchEntryMap]);
    const onRoleChange = (speaker, audioSource, role) => __awaiter(void 0, void 0, void 0, function* () {
        streamSocket.send(JSON.stringify({
            type: "set_active_role",
            speaker: speaker,
            role: role,
            audio_source: audioSource,
        }));
        const updatedSpeakerToRoleSwitchEntry = new Map(speakerToRoleSwitchEntryMap);
        updatedSpeakerToRoleSwitchEntry.set(getSpeakerKey(speaker, audioSource), {
            speaker,
            audioSource,
            role,
        });
        setSpeakerToRoleSwitchEntryMap(updatedSpeakerToRoleSwitchEntry);
    });
    const onManualNoteChange = (note_text) => {
        streamSocket.send(JSON.stringify({
            type: "set_manual_note",
            manual_note: note_text,
            client_id: clientId,
        }));
    };
    const onCallReset = () => {
        // Reset the state of the call when the user triggers call restart.
        // Note that we don't reset the zoom bot status, because call restart
        // does not re-trigger zoom bot to leave and join the call.
        // TODO(mark): maybe reload more gracefully without a full page reload.
        // setTranscript([]);
        // setCards([]);
        // setSpeakerToRoleSwitchEntryMap(new Map());
        // setLastNoteGenerationRequestTime(0);
        // resetChatMessages();
        window.location.reload();
    };
    const defaultChatSource = useMemo(() => {
        var _a;
        const sourceId = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.default_chat_source_live_call) !== null && _a !== void 0 ? _a : "knowledge_base";
        return CHAT_SOURCES.find((source) => source.id === sourceId);
    }, [tenant]);
    if (!call) {
        return _jsx("div", { children: "Loading..." });
    }
    const transcriptWidget = (_jsx(Transcript, { isLive: true, transcriptEntries: transcript, startTimeMsec: startTimeMsec, speakerToRoleMap: speakerToRolesMap, onRoleChange: onRoleChange, roleSwitcherRef: roleSwitcherRef, scrollToBottom: transcriptScrollToBottom, setScrollToBottom: setTranscriptScrollToBottom }));
    const MOSAIC_ELEMENT_MAP = {
        manualNotes: socketReady ? (_jsx(ManualNotes, { enabled: manualNoteEnabled, roleSwitcherRef: roleSwitcherRef, manualNote: manualNote, onManualNoteChange: onManualNoteChange, isMosaicScrolling: isMosaicScrolling, setManualNote: setManualNote })) : (_jsx("div", Object.assign({ className: "h-full w-full flex items-center justify-center" }, { children: _jsx(WaitingSpinner, { text: "Connecting... may need to refresh page." }) }))),
        feedAssist: (_jsx(FeedAssist, { allCards: cards, callId: callId, clientId: clientId, chatMessages: chatMessages, addChatMessage: addChatMessage, collapsed: feedCollapsed, setCollapse: setFeedCollapsed, defaultChatSource: defaultChatSource || KNOWLEDGE_BASE_CHAT_SOURCE, socketReady: socketReady, allLlmOutputFeedback: allLlmOutputFeedback !== null && allLlmOutputFeedback !== void 0 ? allLlmOutputFeedback : [], updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation })),
        dashboard: (_jsx(LiveCallMainDashboard, { call: call, startTimeMsec: startTimeMsec, notes: Array.from(notesMap.values()), setCallAndNotes: setCallAndNotes, updateNotesMap: updateNotesMap, lastRecommendationReceivedTimeMsecs: lastRecommendationTimeMsecs.current, callSummaries: liveCallSummaries, trackerItemsPanelCollapsed: trackerItemsPanelCollapsed, transcriptWidget: transcriptWidget })),
    };
    const onMosaicChange = (currentNode) => {
        // Checking currentNode to ensure that a change has actually occurred in the Mosaic layout
        if (!currentNode)
            return;
        setMosaicState(currentNode);
        setTranscriptScrollToBottom(true);
        setIsMosaicScrolling(true);
        const draggedToSnapCloseWidth = chatShouldSnapClosed(currentNode);
        if (draggedToSnapCloseWidth && !feedCollapsed) {
            setFeedCollapsed(true);
        }
        else if (!draggedToSnapCloseWidth && feedCollapsed) {
            setFeedCollapsed(false);
        }
        const draggedToCollapseTrackerItemsPanel = shouldCollapseTrackerItemsPanel(currentNode);
        if (draggedToCollapseTrackerItemsPanel && !trackerItemsPanelCollapsed) {
            setTrackerItemsPanelCollapsed(true);
        }
        else if (!draggedToCollapseTrackerItemsPanel &&
            trackerItemsPanelCollapsed) {
            setTrackerItemsPanelCollapsed(false);
        }
    };
    const onMosaicRelease = (currentNode) => {
        setIsMosaicScrolling(false);
        if (!currentNode)
            return;
        const updatedTree = snapMosaic(currentNode);
        setMosaicState(updatedTree);
        if (userProfile &&
            userProfile.versioned_live_call_mosaic_state[mosaicStateLiveVersion] !==
                updatedTree) {
            updateUserProfile(Object.assign(Object.assign({}, userProfile), { versioned_live_call_mosaic_state: Object.assign(Object.assign({}, userProfile.versioned_live_call_mosaic_state), { [mosaicStateLiveVersion]: updatedTree }) }));
        }
    };
    return (
    // h-[calc(100vh - 3.125rem)] lg:h-screen is to allow for the "expand your browser" notice on small screens.
    _jsxs("div", Object.assign({ className: "bg-neutral-100 lg:h-screen flex flex-col" }, { children: [_jsx(CallHeading, { call: call, zoomBotStatus: zoomBotStatus, onRoleChange: onRoleChange, speakerToRoleMap: speakerToRolesMap, onCallReset: onCallReset, talkTimeMetrics: talkTimeMetrics, speechRateMetrics: speechRateMetrics, socketReady: socketReady, updateCall: updateCall }), _jsx("div", Object.assign({ className: "hidden md:flex grow overflow-hidden w-full h-screen lg:h-full" }, { children: _jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => _jsx("div", { children: MOSAIC_ELEMENT_MAP[id] }), value: mosaicState, onChange: onMosaicChange, onRelease: onMosaicRelease, className: "my-mosaic h-full" }) })), _jsx("div", Object.assign({ className: "flex grow overflow-hidden w-full h-screen lg:h-full md:hidden" }, { children: _jsx(LiveCallMainDashboard, { call: call, startTimeMsec: startTimeMsec, notes: Array.from(notesMap.values()), setCallAndNotes: setCallAndNotes, updateNotesMap: updateNotesMap, lastRecommendationReceivedTimeMsecs: lastRecommendationTimeMsecs.current, callSummaries: liveCallSummaries, trackerItemsPanelCollapsed: trackerItemsPanelCollapsed, transcriptWidget: transcriptWidget, notesWidget: MOSAIC_ELEMENT_MAP.manualNotes, chatWidget: _jsx("div", Object.assign({ className: "w-full h-full px-4" }, { children: _jsx(LiveCardsChatBot, { allCards: cards, callId: callId, clientId: clientId, chatMessages: chatMessages, addChatMessage: addChatMessage, setCollapse: () => { }, defaultChatSource: defaultChatSource || KNOWLEDGE_BASE_CHAT_SOURCE, socketReady: socketReady, allLlmOutputFeedback: allLlmOutputFeedback !== null && allLlmOutputFeedback !== void 0 ? allLlmOutputFeedback : [], updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) })) }) }))] })));
};
export default LiveCall;
