import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import classNames from "../class_names";
import { Button } from "../components/button";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
export const ProvenanceCard = ({ record }) => {
    const renderProvenanceQuote = (quote, idx) => {
        return (_jsx("div", Object.assign({ className: classNames("text-wds-gray-6 text-sm whitespace-pre-wrap break-all shrink-0", idx > 1 ? "line-clamp-2" : "line-clamp-3") }, { children: quote && quote.length > 0 ? '"' + quote + '"' : "No quote available" }), idx));
    };
    const getDataSourceLabel = (dataSource) => {
        switch (dataSource) {
            case "SecDocumentsForCompany":
                return "SEC Documents";
            case "WebData":
                return "Web Source";
            default:
                return dataSource;
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start w-96 rounded-lg shadow-lg border-x border-b border-wds-gray-3 bg-white" }, { children: [_jsx("div", { className: "h-1 self-stretch bg-gradient-to-r from-[#0171F5] to-[#AD15B0]" }), _jsxs("div", Object.assign({ className: "flex flex-col self-stretch p-4 items-start gap-4" }, { children: [_jsx("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: _jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm" }, { children: getDataSourceLabel(record.data_source) })) })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [(record.title || record.subtitle) && (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-0.5 self-stretch" }, { children: [record.title && (_jsx("span", Object.assign({ className: "font-bold text-sm" }, { children: record.title }))), record.subtitle && (_jsx("span", Object.assign({ className: "text-wds-gray-5 font-medium text-xs" }, { children: record.subtitle })))] }))), (record.quotes && record.quotes.length && (_jsx("div", Object.assign({ className: "flex flex-col gap-1 max-h-40 overflow-y-auto" }, { children: record.quotes &&
                                            record.quotes.length &&
                                            record.quotes.map((quote, idx) => renderProvenanceQuote(quote, idx)) })))) || _jsx("div", {})] })), record.url && (_jsx(Link, Object.assign({ to: record.url, target: "_blank" }, { children: _jsxs(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 gap-2 text-wds-blue-3 hover:bg-transparent" }, { children: ["Review", _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4 shrink-0" })] })) })))] }))] }))] })));
};
