var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import FieldSchemaRenderer from "./field_schema_renderer";
import FieldValueEditor from "./field_value_editor";
import classNames from "../class_names";
import FieldValueRenderer, { CrmEventTargetValueRenderer, } from "./field_value_renderer";
import WaitingSpinner from "../waiting_spinner";
import { isCrmEventPushItem } from "./utils";
export const FieldMappingNewValue = ({ editNewValue, setEditNewValue, postCallOutputItem, onNewValueChange, fieldSchema, expanded, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [newContent, setNewContent] = useState((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
    const newValueDivRef = useRef(null);
    const onNewValueEdit = (content) => {
        setNewContent(content);
    };
    const handleNewValueTextareaKeyDown = (event) => {
        var _a, _b;
        // Users can come out of the edit mode by pressing either Esc or click outside.
        if (event.key === "Escape") {
            // DO NOT update the value.
            setNewContent((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
            setEditNewValue(false);
        }
    };
    useEffect(() => {
        // Attach a click event listener to document to exit edit mode on outside click
        const handleOutsideClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const clickedElement = event.target;
            const isClickedInsideComponent = (_a = newValueDivRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickedElement);
            // The clicked inside app check ensures that we treat only those clicks as
            // "outside" that are not in the Radix portal. Otherwise, clicks in the Radix
            // components rendered in a portal like DropdownMenu is also considered an
            // outside click and thus disrupts the functioning of this hook.
            const isClickedInsideApp = (_b = document
                .getElementById("app")) === null || _b === void 0 ? void 0 : _b.contains(clickedElement);
            if (isClickedInsideApp && !isClickedInsideComponent) {
                yield onNewValueChange(newContent);
                setEditNewValue(false);
            }
        });
        if (editNewValue) {
            // Capture the click event on the document to exit edit mode on outside click
            document.addEventListener("click", handleOutsideClick, true);
        }
        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        };
    }, [editNewValue, newContent]);
    useEffect(() => {
        var _a, _b;
        setNewContent((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
    }, [postCallOutputItem]);
    const itemIgnored = postCallOutputItem.state === "IGNORED";
    const editDisabled = postCallOutputItem.state === "PROCESSED" ||
        postCallOutputItem.state === "PROCESSING" ||
        isCrmEventPushItem(postCallOutputItem) ||
        itemIgnored;
    return (_jsxs("div", Object.assign({ className: "inline-flex gap-4 items-start flex-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start flex-col" }, { children: [_jsx("span", Object.assign({ className: "w-32 text-gray-600 font-medium text-sm shrink-0" }, { children: isCrmEventPushItem(postCallOutputItem) ? "Details" : "New value" })), !isCrmEventPushItem(postCallOutputItem) && (_jsx(FieldSchemaRenderer, { id: `${postCallOutputItem.id}`, fieldSchema: fieldSchema }))] })), _jsx("div", Object.assign({ className: "inline-flex flex-col gap-2 flex-1 self-stretch" }, { children: editNewValue ? (_jsx("div", Object.assign({ ref: newValueDivRef, className: "self-stretch flex-1" }, { children: _jsx(FieldValueEditor, { postCallOutputId: postCallOutputItem.callId, value: newContent, fieldSchema: fieldSchema, onValueEdit: onNewValueEdit, handleKeyDown: handleNewValueTextareaKeyDown }) }))) : (_jsx("div", Object.assign({ ref: newValueDivRef, className: classNames("text-black font-normal whitespace-pre-wrap overflow-hidden", editDisabled
                        ? ""
                        : "hover:outline-none hover:cursor-pointer hover:bg-gray-100 hover:rounded-lg hover:px-2 hover:py-1 hover:-mt-1 hover:-ml-2 hover:self-start w-full h-full"), onClick: () => {
                        if (editDisabled) {
                            return;
                        }
                        setEditNewValue(true);
                    } }, { children: isCrmEventPushItem(postCallOutputItem) ? (_jsx(CrmEventTargetValueRenderer, { postCallOutputItemId: postCallOutputItem.callId, value: (_d = (_c = postCallOutputItem.valueToUpdate) !== null && _c !== void 0 ? _c : postCallOutputItem.proposedValue) !== null && _d !== void 0 ? _d : "", fieldSchema: fieldSchema, expanded: expanded || editNewValue })) : (_jsx(FieldValueRenderer, { postCallOutputItemId: postCallOutputItem.callId, value: (_f = (_e = postCallOutputItem.valueToUpdate) !== null && _e !== void 0 ? _e : postCallOutputItem.proposedValue) !== null && _f !== void 0 ? _f : "", fieldSchema: fieldSchema, expanded: expanded || editNewValue })) }))) }))] })));
};
export const EmptyTargetFieldMappingValue = ({ postCallOutputItem, loading, }) => {
    return (_jsxs("div", Object.assign({ className: classNames("flex items-start py-2", postCallOutputItem.state === "IGNORED" ? "opacity-50" : "", loading ? "relative opacity-50" : "") }, { children: [loading && (_jsx("div", Object.assign({ className: "absolute inset-0 flex items-center justify-center" }, { children: _jsx(WaitingSpinner, { text: "" }) }))), _jsxs("div", Object.assign({ className: "inline-flex pr-12 pl-4 gap-4 items-start" }, { children: [_jsxs("div", Object.assign({ className: "w-32 text-gray-500 font-medium text-sm shrink-0" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-5 h-5" }), "Content captured by Wiser"] })), _jsx("div", Object.assign({ className: "grow self-stretch break-all text-black font-normal whitespace-pre-wrap line-clamp-5 hover:line-clamp-none" }, { children: postCallOutputItem.contentCaptured }))] }))] })));
};
