var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import classNames from "../class_names";
import { PostCallOutputTarget, } from "../types";
import IntegrationSelector from "./integration_selector";
import { SalesforceDestinationSelector } from "./sfdc_target_renderers";
import { SlackDestinationSelector } from "./slack_target_renderers";
import { WiserCrmDestinationSelector } from "./wiser_crm_target_renderers";
import { HubspotDestinationSelector } from "./hubspot_target_renderer";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
const FieldTargetPopover = (props) => {
    var _a;
    const [selectedIntegration, setSelectedIntegration] = useState((_a = props.postCallOutputItem.target) !== null && _a !== void 0 ? _a : null);
    const [targetDetails, setTargetDetails] = useState(null);
    const [executePostCallAfterNMinutes, setExecutePostCallAfterNMinutes] = useState(props.executePostCallAfterNMinutes);
    const [savingNewTarget, setSavingNewTarget] = useState(false);
    const buttonRef = useRef(null);
    const shouldRenderAboveButton = () => {
        if (!buttonRef.current)
            return false;
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const spaceAboveButton = buttonRect.top + window.scrollY;
        const spaceBelowButton = window.innerHeight - buttonRect.bottom + window.scrollY;
        return spaceAboveButton > spaceBelowButton;
    };
    useEffect(() => {
        var _a;
        if (props.postCallIntegrations.length === 0) {
            return;
        }
        setSelectedIntegration((_a = props.postCallOutputItem.target) !== null && _a !== void 0 ? _a : props.postCallIntegrations[0].name);
    }, [props.postCallOutputItem, props.postCallIntegrations]);
    useEffect(() => {
        setExecutePostCallAfterNMinutes(props.executePostCallAfterNMinutes);
    }, [props.executePostCallAfterNMinutes]);
    const onSaveClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!targetDetails || !selectedIntegration) {
            return;
        }
        yield props.onDataItemTargetUpdate(
        /* target= */ selectedIntegration, 
        /* targetDetails= */ targetDetails, 
        /* executePostCallAfterNMinutes= */ executePostCallAfterNMinutes, 
        /* updateType= */ props.postCallOutputItem.fieldMap.updateType);
    });
    if (props.disabled || props.postCallIntegrations.length === 0) {
        return _jsx(_Fragment, { children: props.popoverButton });
    }
    const autosendSettingsEnabled = useFeatureFlagEnabled("POST_CALL_AUTOSEND");
    return (_jsx(Popover, Object.assign({ className: "relative" }, { children: ({ open, close }) => {
            var _a, _b, _c, _d;
            return (_jsxs(_Fragment, { children: [_jsx(Popover.Button, Object.assign({ as: "div", ref: buttonRef, className: classNames("flex hover:outline-none", open ? "bg-gray-200" : "") }, { children: props.popoverButton })), open && (_jsxs(_Fragment, { children: [_jsx(Popover.Overlay, { className: "fixed inset-0 bg-black opacity-30 overflow-hidden" }), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-200", enterFrom: "opacity-0 translate-y-1", enterTo: "opacity-100 translate-y-0", leave: "transition ease-in duration-150", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-1", show: open }, { children: _jsx(Popover.Panel, Object.assign({ className: classNames("w-full md:w-96 flex pt-1 absolute right-4 z-10 transform shrink-0", shouldRenderAboveButton() ? "bottom-full" : "top-full") }, { children: _jsxs("div", Object.assign({ className: "flex flex-grow items-start rounded-lg border border-solid border-gray-300 bg-white shadow-lg" }, { children: [_jsx("div", Object.assign({ className: "w-52 p-4 flex flex-col self-stretch items-startshrink-0" }, { children: _jsx(IntegrationSelector, { onSelect: (item) => {
                                                        setSelectedIntegration(item);
                                                        setTargetDetails(null);
                                                    }, defaultSelected: props.postCallOutputItem.target, integrations: props.postCallIntegrations }) })), _jsxs("div", Object.assign({ className: "flex flex-col flex-grow justify-between gap-6 items-end self-stretch p-6 bg-gray-100" }, { children: [selectedIntegration ===
                                                        PostCallOutputTarget.SALESFORCE && (_jsx(_Fragment, { children: _jsx(SalesforceDestinationSelector, { call: props.call, selectedTarget: props.postCallOutputItem.target ===
                                                                PostCallOutputTarget.SALESFORCE
                                                                ? props.postCallOutputItem
                                                                    .targetDetails
                                                                : undefined, salesforceFields: (_b = (_a = props.integrationsData.find((integrationData) => integrationData.name ===
                                                                PostCallOutputTarget.SALESFORCE)) === null || _a === void 0 ? void 0 : _a.sfdcFields) !== null && _b !== void 0 ? _b : {
                                                                Account: [],
                                                                Contact: [],
                                                                Opportunity: [],
                                                            }, onTargetDetailsChange: setTargetDetails }) })), selectedIntegration === PostCallOutputTarget.SLACK && (_jsx(_Fragment, { children: _jsx(SlackDestinationSelector, { selectedTarget: props.postCallOutputItem.target ===
                                                                PostCallOutputTarget.SLACK
                                                                ? props.postCallOutputItem
                                                                    .targetDetails
                                                                : undefined, onTargetDetailsChange: setTargetDetails }) })), selectedIntegration ===
                                                        PostCallOutputTarget.WISER_CRM && (_jsx(_Fragment, { children: _jsx(WiserCrmDestinationSelector, { call: props.call, selectedTarget: props.postCallOutputItem.target ===
                                                                PostCallOutputTarget.WISER_CRM
                                                                ? props.postCallOutputItem
                                                                    .targetDetails
                                                                : undefined, crmFields: props.integrationsData.find((integrationData) => integrationData.name ===
                                                                PostCallOutputTarget.WISER_CRM).crmFields, onTargetDetailsChange: setTargetDetails }) })), selectedIntegration ===
                                                        PostCallOutputTarget.HUBSPOT && (_jsx(_Fragment, { children: _jsx(HubspotDestinationSelector, { call: props.call, selectedTarget: props.postCallOutputItem.target ===
                                                                PostCallOutputTarget.HUBSPOT
                                                                ? props.postCallOutputItem
                                                                    .targetDetails
                                                                : undefined, hubspotFields: (_d = (_c = props.integrationsData.find((integrationData) => integrationData.name ===
                                                                PostCallOutputTarget.HUBSPOT)) === null || _c === void 0 ? void 0 : _c.crmFields) !== null && _d !== void 0 ? _d : {
                                                                Account: [],
                                                                Contact: [],
                                                                Opportunity: [],
                                                            }, onTargetDetailsChange: setTargetDetails }) })), autosendSettingsEnabled && (_jsxs("div", Object.assign({ className: "flex flex-grow flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Automatically send after this many minutes (zero means never)" })), _jsx("input", { type: "number", value: executePostCallAfterNMinutes, onChange: (e) => {
                                                                    var _a;
                                                                    if (!targetDetails)
                                                                        return;
                                                                    setExecutePostCallAfterNMinutes(Math.max((_a = parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0, 0));
                                                                }, className: "w-full border border-gray-300 rounded-lg text-sm text-gray-900 focus:ring-0 focus:outline-none focus:border-gray-300 placeholder:text-wds-gray-4" })] }))), _jsxs("div", Object.assign({ className: "flex items-start self-stretch justify-between" }, { children: [_jsx("button", Object.assign({ className: classNames("flex items-center justify-center px-4 py-2 rounded-full border border-solid border-gray-300 bg-white text-gray-500 text-sm font-bold"), onClick: () => {
                                                                    var _a, _b;
                                                                    setSelectedIntegration((_a = props.postCallOutputItem.target) !== null && _a !== void 0 ? _a : null);
                                                                    setTargetDetails((_b = props.postCallOutputItem.targetDetails) !== null && _b !== void 0 ? _b : null);
                                                                    close();
                                                                } }, { children: "Cancel" })), _jsx("button", Object.assign({ className: classNames("flex items-center justify-center px-4 py-2 rounded-full bg-blue-500 text-white text-sm font-bold", savingNewTarget || !targetDetails
                                                                    ? "bg-gray-500 hover:cursor-normal"
                                                                    : ""), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                                    setSavingNewTarget(true);
                                                                    yield onSaveClick();
                                                                    setSavingNewTarget(false);
                                                                    close();
                                                                }), disabled: savingNewTarget || !targetDetails }, { children: savingNewTarget ? "Saving" : "Save" }))] }))] }))] })) })) }))] }))] }));
        } })));
};
export default FieldTargetPopover;
