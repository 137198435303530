var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { getChatHistory } from "../common/endpoints";
import useChatMessages from "../live_cards_chat_bot/use_chat_messages";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useStreamSocket, } from "../common/stream_socket";
import ChatSourceMenu, { PRE_CALL_CHAT_SOURCE, } from "../live_cards_chat_bot/chat_source_menu";
import { useLlmOutputFeedback } from "../llm_output_feedback/use_llm_output_feedback";
import Message from "../live_cards_chat_bot/message";
export const ChatBox = ({ call, defaultChatSource, chatSources }) => {
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const { chatMessages, addChatMessage, resetChatMessages, handlePlaybookRecommendations, handleChatMessage, handleEchoChat, loadChatHistory, handleChatProgress, } = useChatMessages();
    const [collapsed, setCollapse] = useState(true);
    const [query, setQuery] = useState("");
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    const [forceSource, setForceSource] = useState(false);
    const [selectedUserMessageIndex, setSelectedUserMessageIndex] = useState(-1);
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId: call.id });
    const chatInputRef = useRef(null);
    useEffect(() => {
        if (chatInputRef.current) {
            chatInputRef.current.focus();
        }
    }, [chatInputRef]);
    useEffect(() => {
        setSocketReady(false);
        resetChatMessages();
        getChatHistory(call.id).then((chatHistory) => loadChatHistory(chatHistory, clientId));
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.addListener("echo_back_chat", (message) => handleEchoChat(message, clientId));
        streamSocket.addListener("playbook_recommendations", handlePlaybookRecommendations);
        streamSocket.addListener("chat_progress", handleChatProgress);
        // We just need this to tell that the stream is started; every session
        // begins by sending a note or a placeholder.
        streamSocket.addListener("manual_note", (message) => { });
        streamSocket.addListener("display_notes", (message) => { });
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
        streamSocket.setActiveCall(call.id);
        return () => {
            streamSocket.close();
        };
    }, [call]);
    const userMessages = chatMessages.filter((message) => message.sender === "User");
    useEffect(() => {
        if (selectedUserMessageIndex >= 0) {
            setQuery(userMessages[selectedUserMessageIndex].messageContent);
        }
        else {
            setQuery("");
        }
    }, [selectedUserMessageIndex]);
    const selectNextUserMessage = () => {
        if (selectedUserMessageIndex == -1)
            return;
        if (selectedUserMessageIndex >= userMessages.length - 1) {
            // Clear the query if we're at the end of the list.
            setSelectedUserMessageIndex(-1);
            return;
        }
        setSelectedUserMessageIndex(selectedUserMessageIndex + 1);
    };
    const selectPreviousUserMessage = () => {
        if (selectedUserMessageIndex == 0)
            return;
        if (selectedUserMessageIndex == -1) {
            // Default to the latest message.
            setSelectedUserMessageIndex(userMessages.length - 1);
            return;
        }
        setSelectedUserMessageIndex(selectedUserMessageIndex - 1);
    };
    const defaultSelectedChatSource = useMemo(() => {
        if (!!defaultChatSource)
            return defaultChatSource;
        if (chatSources && chatSources.length > 0)
            return chatSources[0];
        return PRE_CALL_CHAT_SOURCE;
    }, [defaultChatSource, chatSources]);
    const [selectedSource, setSelectedSource] = useState(defaultSelectedChatSource);
    const accountId = call.account ? call.account.id : undefined;
    const submitQuery = () => {
        if (!streamSocket || !socketReady)
            return;
        addUserMessageToChatAndRespond(query, forceSource ? selectedSource.id : undefined);
    };
    const botMessages = chatMessages.filter((message) => message.sender === "Bot");
    // The first message is always a welcome message we can ignore.
    const message = botMessages.length > 1 ? botMessages[botMessages.length - 1] : undefined;
    const addUserMessageToChatAndRespond = (userMessage, forceSource) => __awaiter(void 0, void 0, void 0, function* () {
        addChatMessage({
            sender: "Bot",
            messageType: "Loading",
        });
        streamSocket.send(JSON.stringify({
            type: "get_best_card_from_string_input",
            inputString: userMessage,
            forceSource: forceSource !== null && forceSource !== void 0 ? forceSource : selectedSource.id,
            clientId: clientId,
            chatAgainstAccountId: accountId,
            useCardInfo: selectedSource.options.useCardInfo,
        }));
    });
    return (_jsxs("div", Object.assign({ className: "w-full flex flex-col space-y-2" }, { children: [_jsx("div", Object.assign({ className: "rounded-full border-0 p-[2px]", style: {
                    backgroundImage: `linear-gradient(to top right, #0171F5, #C026D3)`,
                } }, { children: _jsxs("div", Object.assign({ className: "flex w-full rounded-full border-0 bg-wds-gray-1 p-1 space-x-2" }, { children: [_jsx("input", { className: "grow rounded-full border-none px-4 py-2 bg-wds-gray-1 text-wds-gray-5 focus:ring-0", placeholder: "Ask Wiser anything about this deal...", value: query, onChange: (e) => setQuery(e.target.value), onKeyDown: (e) => {
                                if (e.key === "Enter")
                                    submitQuery();
                                if (e.key === "ArrowUp") {
                                    selectPreviousUserMessage();
                                }
                                if (e.key === "ArrowDown") {
                                    selectNextUserMessage();
                                }
                            }, disabled: !socketReady, ref: chatInputRef }), _jsx("div", Object.assign({ className: "flex" }, { children: _jsx(ChatSourceMenu, { chatSources: chatSources, selectedSource: selectedSource, setSelectedSource: (chatSource) => {
                                    setSelectedSource(chatSource);
                                    setForceSource(true);
                                }, renderedInCard: true, className: "bg-wds-gray-1" }) })), _jsxs("button", Object.assign({ className: "flex flex-row space-x-2 px-3 rounded-full items-center justify-center text-white", style: {
                                backgroundImage: `linear-gradient(to top right, #0171F5, #C026D3)`,
                            }, disabled: !socketReady || query.length === 0, onClick: () => submitQuery() }, { children: [_jsx(SparklesIcon, { className: "flex w-5 h-5 text-white" }), _jsx("span", { children: "Ask" })] }))] })) })), !!message && (_jsx("div", Object.assign({ className: "bg-wds-gray-1 rounded-lg shadow-lg p-2" }, { children: _jsx(Message, { message: chatMessages[chatMessages.length - 1], addUserMessageToChatAndRespond: addUserMessageToChatAndRespond, userLlmOutputFeedback: message.sender === "Bot" &&
                        message.messageType === "Card" &&
                        message.card.provenanceLogId
                        ? allLlmOutputFeedback === null || allLlmOutputFeedback === void 0 ? void 0 : allLlmOutputFeedback.find((f) => f.provenance_log_id === message.card.provenanceLogId)
                        : undefined, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) })))] })));
};
