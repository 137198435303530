var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { flexRender } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow, } from "./table";
import { DataTablePagination } from "./data_table_pagination";
import classNames from "../class_names";
import { getCommonPinningStyles } from "../table_helpers";
import { ColumnResizer } from "./column_resizer";
export function DataTable(_a) {
    var { table, floatingBar = null, footer = null, children, className } = _a, props = __rest(_a, ["table", "floatingBar", "footer", "children", "className"]);
    /**
     * Instead of calling `column.getSize()` on every render for every header
     * and especially every data cell (very expensive),
     * we will calculate all column sizes at once at the root table level in a useMemo
     * and pass the column sizes down as CSS variables to the <table> element.
     */
    const columnSizeVars = React.useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes = {};
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            colSizes[`--header-${header.id}-size`] = header.getSize();
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
        }
        return colSizes;
    }, [
        // In some cases, the columns in the table are added after the BE API
        // resolves, and hence we want to recompute the sizing of the new columns
        table.getFlatHeaders(),
        table.getState().columnSizingInfo,
        table.getState().columnSizing,
    ]);
    return (_jsxs("div", Object.assign({ className: classNames("space-y-2.5 overflow-auto", className) }, props, { children: [children, _jsx("div", Object.assign({ className: "overflow-auto rounded-md border h-[73vh]" }, { children: _jsxs(Table, Object.assign({ style: Object.assign(Object.assign({}, columnSizeVars), { width: table.getTotalSize() }) }, { children: [_jsx(TableHeader, Object.assign({ className: "sticky top-0 z-10" }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, { children: headerGroup.headers.map((header) => {
                                    return (_jsxs(TableHead, Object.assign({ colSpan: header.colSpan, style: Object.assign(Object.assign({}, getCommonPinningStyles({ column: header.column })), { width: `calc(var(--header-${header === null || header === void 0 ? void 0 : header.id}-size) * 1px)` }) }, { children: [_jsx(ColumnResizer, { header: header }), header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())] }), header.id));
                                }) }, headerGroup.id))) })), table.getState().columnSizingInfo.isResizingColumn ? (_jsx(MemoisedTBody, { table: table })) : (_jsx(TBody, { table: table })), footer && _jsx(TableFooter, { children: footer })] })) })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2.5" }, { children: [_jsx(DataTablePagination, { table: table }), table.getFilteredSelectedRowModel().rows.length > 0 && floatingBar] }))] })));
}
function TBody({ table }) {
    var _a;
    return (_jsx(TableBody, { children: ((_a = table.getRowModel().rows) === null || _a === void 0 ? void 0 : _a.length) ? (table.getRowModel().rows.map((row) => (_jsx(TableRow, Object.assign({ "data-state": row.getIsSelected() && "selected" }, { children: row.getVisibleCells().map((cell) => (_jsx(TableCell, Object.assign({ style: Object.assign(Object.assign({}, getCommonPinningStyles({ column: cell.column })), { width: `calc(var(--col-${cell.column.id}-size) * 1px)` }) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))) }), row.id)))) : (_jsx(TableRow, { children: _jsx(TableCell, Object.assign({ colSpan: table.getAllColumns().length, className: "h-24 text-center" }, { children: "No results." })) })) }));
}
// Memoize the TBody component
// This is essential when trying to resize 1000+ rows
// For small data it is fine though
const MemoisedTBody = React.memo(TBody, (prev, next) => {
    return (prev.table.options.data === next.table.options.data &&
        prev.table.getState().columnVisibility ===
            next.table.getState().columnVisibility &&
        prev.table.getState().columnSizing === next.table.getState().columnSizing);
});
