var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from "react-query";
import { queryOmniSearch } from "../common/endpoints";
import { AsyncSearchSelect } from "../components/async_search_select";
import { PhoneIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import { formatDate } from "../strings";
import { useNavigate } from "react-router-dom";
const OmniSearch = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const omniSearchFetcher = (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const searchResponse = yield queryClient.fetchQuery({
            queryFn: () => queryOmniSearch(userQuery, cursor),
        });
        return {
            results: searchResponse.results.map((result) => {
                return {
                    label: result.name,
                    value: result.id,
                    details: {
                        type: result.type,
                        website: result.website,
                        datetime: result.datetime ? new Date(result.datetime) : undefined,
                    },
                };
            }),
            next_cursor: searchResponse.next_cursor,
        };
    });
    const sectionSeparator = (options) => {
        return [
            {
                title: "Calls",
                options: options.filter((option) => option.details && option.details.type === "LiveCall"),
            },
            {
                title: "Accounts",
                options: options.filter((option) => option.details && option.details.type === "Account"),
            },
        ];
    };
    return (_jsx(AsyncSearchSelect, { dataFetcher: omniSearchFetcher, placeholder: "Search for calls, accounts...", className: "overflow-x-hidden max-h-96", onSelect: (option) => {
            if (option && option.details) {
                if (option.details.type === "LiveCall") {
                    navigate(`/home/call/${option.value}`);
                }
                else if (option.details.type === "Account") {
                    navigate(`/home/account/${option.value}`);
                }
            }
        }, itemRenderer: (option, userQuery) => {
            // Escape special regex characters in the queryString to avoid issues
            const escapedQueryString = userQuery.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            // Create a regex pattern with global and case-insensitive flags
            const regex = new RegExp(`(${escapedQueryString})`, "gi");
            // Split the text by the queryString, keeping the matches as part of the result
            const parts = option.label.split(regex);
            return (_jsxs("div", Object.assign({ className: "flex flex-row space-x-4 items-center" }, { children: [option.details.type === "LiveCall" ? (_jsx("div", Object.assign({ className: "flex rounded-lg bg-wds-blue-1 h-10 w-10 justify-center items-center" }, { children: _jsx(PhoneIcon, { className: "w-5 h-5 text-wds-blue-5" }) }))) : (_jsx("img", { src: `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${option.details.website}&size=128`, className: "h-10 w-10 rounded-lg" })), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "flex truncate shrink-0 text-ellipsis text-wds-gray-6" }, { children: parts.map((value) => (_jsx("span", Object.assign({ className: classNames(value.toLowerCase() === userQuery.toLocaleLowerCase()
                                        ? "text-wds-blue-4"
                                        : "text-wds-gray-6") }, { children: value })))) })), option.details.datetime && (_jsx("div", Object.assign({ className: "text-xs text-wds-gray-5" }, { children: formatDate(option.details.datetime) })))] }))] })));
        }, separator: sectionSeparator }));
};
export default OmniSearch;
