import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-mosaic-component/react-mosaic-component.css";
import "../index.css";
import VideoPlayback from "../video_playback";
import { getPlaybackPage } from "../common/endpoints";
import Transcript from "../live_call/transcript";
import HomePageManualNotes from "../home/home_page_manual_notes";
import { Tab } from "@headlessui/react";
import classNames from "../class_names";
import PreCallPrep from "../pre_call_prep";
import TrackerV2 from "../live_call/tracker_v2";
import { CenterScreen } from "../settings/styled_components";
import { SettingsLoader } from "../common/loaders";
import { CallHighlights } from "../call_highlights";
import tw from "tailwind-styled-components";
import amplitudeInstance from "../amplitude";
import { getCallSourceSupported, getMeetingType, } from "../video_playback_status_utils";
import DataProcessingTab from "../post_call/data_processing_tab";
import CallHeadingV3 from "../home/call_heading_v3";
import { TRANSCRIPT_CHAT_SOURCE } from "../live_cards_chat_bot/chat_source_menu";
import { ChatBox } from "../home/chat_box_v3";
import VideoShareButton from "../video_share_button";
const TabContainer = tw.div `
  bg-white
  border
  rounded-2xl
  w-full
`;
var DashboardItem;
(function (DashboardItem) {
    DashboardItem["HIGHLIGHTS"] = "Highlights";
    DashboardItem["TRACKERS"] = "Trackers";
    DashboardItem["TRANSCRIPT"] = "Transcript";
    DashboardItem["NOTES"] = "Notes";
    DashboardItem["RESEARCH"] = "Research";
    DashboardItem["TASKS"] = "Tasks";
})(DashboardItem || (DashboardItem = {}));
/**
 * The Video playback page with transcript and other tabs
 * @param {string | null} subTab - The subtab to display, passing empty displays trackers
 * @returns
 */
const PostCallPage = (props) => {
    const { callIdParam } = useParams();
    const initialVideoTimeSec = new URLSearchParams(window.location.search).get("t");
    const navigate = useNavigate();
    const callId = Number(callIdParam);
    const subTab = window.location.hash;
    const [call, setCall] = useState(null);
    const [callNotes, setCallNotes] = useState([]);
    const [playbookName, setPlaybookName] = useState("Playbook");
    const [videoUrl, setVideoUrl] = useState("");
    const [timeline, setTimeline] = useState([]);
    const [transcript, setTranscript] = useState([]);
    const [currentVideoTime, setCurrentVideoTime] = useState(initialVideoTimeSec ? Number(initialVideoTimeSec) * 1000 : 0);
    const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(0);
    const [videoStartMsec, setVideoStartMsec] = useState(0);
    // Determines what transcript part to highlight.
    // Usually the same as what we're playing, unless someone has linked into a
    // transcript but not pushed play yet.
    const [currentTranscriptTimestamp, setCurrentTranscriptTimestamp] = useState(0);
    const transcriptLengthMsec = useMemo(() => {
        if (transcript === undefined)
            return 0;
        if (transcript.length === 0)
            return 0;
        return transcript[transcript.length - 1].endTime - transcript[0].startTime;
    }, [transcript]);
    useEffect(() => {
        const playbackPageResponse = getPlaybackPage(callId);
        playbackPageResponse.then((response) => {
            setCall(response.call);
            setVideoUrl(response.video_url);
            setTimeline(response.speaker_timeline);
            setTranscript(response.transcript);
            setCallNotes(response.notes);
            setPlaybookName(response.playbook_name);
            setVideoStartMsec(response.video_start_msec);
        });
    }, [callId]);
    useEffect(() => {
        for (let i = 0; i < transcript.length; i++) {
            if (transcript[i].startTime - videoStartMsec > currentVideoTime) {
                setCurrentTranscriptIndex(i - 1);
                return;
            }
        }
        setCurrentTranscriptIndex(transcript.length - 1);
    }, [currentVideoTime]);
    const VideoPlaybackContainer = useMemo(() => {
        if (!call) {
            return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
        }
        const meetingType = getMeetingType(call);
        return (_jsx("div", Object.assign({ className: "flex items-start justify-start h-full" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col pb-8 w-full rounded-lg items-center justify-center" }, { children: [_jsx("div", Object.assign({ className: "flex justify-between w-full" }, { children: _jsx(VideoShareButton, { callId: callId, currentVideoTime: currentVideoTime }) })), _jsx(VideoPlayback, { src: videoUrl, playbackTimeline: timeline, currentVideoTime: currentVideoTime, setCurrentVideoTime: setCurrentVideoTime, transcriptLengthMsec: transcriptLengthMsec, callSourceSupported: getCallSourceSupported(meetingType), callState: call.call_state, meetingType: meetingType })] })) })));
    }, [call, videoUrl, currentVideoTime, timeline, transcriptLengthMsec]);
    const roleSwitcherRef = useRef(null);
    const startMsec = videoStartMsec > 0
        ? videoStartMsec
        : transcript.length > 0
            ? transcript[0].startTime
            : 0;
    const dashboardItems = useMemo(() => {
        const items = [];
        if (call) {
            items.push({
                label: DashboardItem.HIGHLIGHTS,
                renderer: () => _jsx(CallHighlights, { call: call }),
            });
            items.push(...[
                {
                    label: DashboardItem.TRACKERS,
                    renderer: () => (_jsx(TabContainer, { children: _jsx(TrackerV2, { call: call, notes: callNotes, updateNotesMap: (updatedNotes) => {
                                setCallNotes((prev) => {
                                    const updatedNoteMap = new Map(updatedNotes.map((note) => [
                                        note.playbook_item_id,
                                        note,
                                    ]));
                                    return prev.map((noteSection) => updatedNoteMap.get(noteSection.playbook_item_id) ||
                                        noteSection);
                                });
                            }, setCallAndNotes: () => { }, lastRecommendationReceivedTimeMsecs: new Map(), goToChatTab: () => { }, trackerItemsPanelCollapsed: false, postCall: true }) })),
                },
                {
                    label: DashboardItem.TRANSCRIPT,
                    renderer: () => (_jsx(Transcript, { isLive: false, transcriptEntries: transcript, startTimeMsec: startMsec, speakerToRoleMap: new Map(), roleSwitcherRef: roleSwitcherRef, scrollToBottom: true, setScrollToBottom: () => { }, currentTranscriptIndex: currentTranscriptIndex, setVideoTime: (timeMsec) => setCurrentVideoTime(timeMsec - videoStartMsec) })),
                },
                {
                    label: DashboardItem.NOTES,
                    renderer: () => (_jsx(TabContainer, { children: _jsx(HomePageManualNotes, { callId: callId }) })),
                },
                {
                    label: DashboardItem.RESEARCH,
                    renderer: () => _jsx(PreCallPrep, { call: call, dashboardView: true }),
                },
                {
                    label: DashboardItem.TASKS,
                    renderer: () => _jsx(DataProcessingTab, { call: call }),
                },
            ]);
        }
        return items;
    }, [call, callNotes, currentTranscriptIndex, transcript, videoStartMsec]);
    const getSelectedTab = (subTab) => {
        if (!subTab) {
            return 0;
        }
        const tabIdx = Object.values(DashboardItem).findIndex((item) => `#${item.toLowerCase()}` === subTab);
        return tabIdx === -1 ? 0 : tabIdx;
    };
    const [selectedTabIndex, setSelectedTabIndex] = useState(getSelectedTab(subTab));
    useEffect(() => {
        if (dashboardItems.length <= selectedTabIndex)
            return;
        if (location.hash !==
            `#${dashboardItems[selectedTabIndex].label.toLowerCase()}`) {
            navigate(`#${dashboardItems[selectedTabIndex].label.toLowerCase()}`);
        }
    }, [selectedTabIndex, dashboardItems]);
    const DashboardContainer = (_jsx("div", Object.assign({ className: "w-full flex flex-col justify-start items-start rounded-lg border-b border-wds-gray-3" }, { children: _jsx(Tab.Group, Object.assign({ selectedIndex: selectedTabIndex, onChange: setSelectedTabIndex }, { children: _jsx(Tab.List, Object.assign({ className: "flex px-8 pt-2 mb-4 md:mb-0 items-center self-stretch gap-1 md:gap-4 border-b border-wds-gray-3 bg-white md:rounded-t-lg" }, { children: dashboardItems.map((item) => (_jsx(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-2 md:px-4 py-2 justify-start items-center flex rounded-t-lg border-wds-blue-3 focus:outline-none focus:ring-0", selected
                        ? "border-b-2 font-bold"
                        : "hover:bg-blue-100 font-normal"), onClick: () => {
                        amplitudeInstance.track("Click Playback Tab", {
                            tabName: item.label,
                        });
                    } }, { children: item.label }), item.label))) })) })) })));
    if (!call) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    const showingTranscriptTab = dashboardItems[selectedTabIndex].label === DashboardItem.TRANSCRIPT;
    return (_jsxs("div", Object.assign({ className: "bg-neutral-100 h-full flex flex-col font-['Lato']" }, { children: [_jsx(CallHeadingV3, { call: call, updateSelectedCall: () => { }, onClose: props.onClose }), _jsxs("div", Object.assign({ className: "flex flex-col w-full overflow-y-auto relative" }, { children: [_jsx("div", Object.assign({ className: "py-4 px-8 w-full bg-white" }, { children: _jsx(ChatBox, { call: call, defaultChatSource: TRANSCRIPT_CHAT_SOURCE }) })), _jsxs("div", Object.assign({ className: "flex flex-col w-full min-h-0 flex-1 hidden lg:flex" }, { children: [_jsx("div", Object.assign({ className: "sticky top-0" }, { children: DashboardContainer })), _jsxs("div", Object.assign({ className: "flex flex-row flex-1 w-full p-8 space-x-6 min-h-0" }, { children: [_jsx("div", Object.assign({ className: classNames("w-1/2", showingTranscriptTab ? "overflow-y-auto" : ""), style: showingTranscriptTab ? { height: "calc(100vh - 150px)" } : {} }, { children: _jsx("div", Object.assign({ className: "pb-8" }, { children: dashboardItems[selectedTabIndex].renderer() })) })), _jsx("div", Object.assign({ className: "w-1/2" }, { children: VideoPlaybackContainer }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col w-full flex-1 px-8 pb-8 lg:hidden" }, { children: [VideoPlaybackContainer, _jsx("div", Object.assign({ className: "sticky top-0 -mx-8" }, { children: DashboardContainer })), dashboardItems[selectedTabIndex].renderer()] }))] }))] })));
};
export default PostCallPage;
